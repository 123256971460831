import { Component, OnInit } from '@angular/core';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import { UserProfile } from 'src/app/utils/user-profile.service';
import { PerfectScrollbarConfigInterface, PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { GetUsersService } from 'src/app/services/get-users.service';
import { AlertService } from 'src/app/components/_alert'
import { AngularFireAuth } from '@angular/fire/auth';
import '../../../../services/mail/smtp.js'
import { AssignmentService } from 'src/app/services/assignment/assignment.service';
import { firestore } from 'firebase';
import * as firebase from 'firebase';

declare let Email: any;
@Component({
  selector: 'app-project-tracker',
  templateUrl: './project-tracker.component.html',
  styleUrls: ['./project-tracker.component.scss']
})
export class ProjectTrackerComponent implements OnInit {
  assgn$: Observable<any>;
  currentUSerSubscription: Subscription;
  currentUser;
  get auth() { return this.profile.auth; }
  user$;
  dateToday = new Date(Date.now()).toLocaleDateString('sv-SE')

  constructor(
    private profile: UserProfile,
    private as: AssignmentService,
    private getUsersService: GetUsersService,
    private afAuth: AngularFireAuth,
    protected alertService: AlertService,
  ) { }

  ngOnInit() {
    console.log(this.dateToday);
    this.currentUSerSubscription = this.profile
    .stream().subscribe(
      r => {
        this.currentUser = r;
      }
    );
    this.user$ = this.afAuth.auth.currentUser;
    this.as.getAllAssignments().subscribe((assgn$)=> {
      this.assgn$ = assgn$,   console.log(assgn$);
    });
  }
  deleteAssgn(id) {
    if (window.confirm('Are you sure you want to delete this assignment?')) {
      this.as.deleteAssignment(id)
    }
    /* ///
    console.log(id);
    this.as.deleteAssignment(id).then(
    (id) => {
      console.log("document with id: " + id + " has been deleted")
    }
    )
    */
  }

  remindEditor(email,firstName,lastName) {
    Email.send({
      Host : 'smtp.gmail.com',
      Username : 'gfc.firebase@gmail.com',
      Password : 'yvmtwwbkzikmskdi',
      To : email,
      From : 'admin@clientmentorportal.com',
      Subject : 'Late Assignment Alert',
      Body : `You are being sent details about a current assignment<br/><br/>
      <b>Hello Editor!</b><br>
      Your current assignment for our client <b>${firstName} ${lastName}</b> is already overdue! Please finish it as soon as possible and report to your admin about your progress. <br><br>
    
          Please login to your account at <a>https://clientmentorportal.com/</a> to accept or reject this assignment.<br/><br/>
          <b>~End of Message.~</b>`
      }).then(
        message => {
  
          if(message === 'OK')
              alert( 'Successfully Sent!');
          else
            alert('Error: '+ message);
  
          }, err => {alert(err); }
      );
      
  }

}


