import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class SidenavService {

  sidenavComponent: MatSidenav;

  constructor() { }

  setNav(nav): void {
    this.sidenavComponent = nav;
  }

  toggle(): void {
    if (typeof this.sidenavComponent !== typeof undefined) {
      this.sidenavComponent.toggle();
    }
  }

}
