import { SharedModule } from './../../../directives/shared.module';
import { RootImageFallbackDirective } from './../../../directives/rootImageDirective.directive';
import { ImageFallbackDirective } from './../../../directives/image-fallback.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  AddToGroupDialogComponent,
  ClientRecipientComponent,
  ConfirmationDialogComponent,
  EmailComponent,
  NicknameDialogComponent
} from './../../plugin-components/client-view/client-recipient/client-recipient.component';
import { DateFormatPipe } from './../../../pipes/date-format.pipe';
import { NgxEmojiPickerModule } from 'ngx-emoji-picker';
import { UploadTaskComponent } from './../../plugin-components/client-view/upload-task/upload-task.component';
import { SchedulingComponent, EventDialogComponent } from './../../plugin-components/editor-view/scheduling/scheduling.component';
import { ClientChatLayoutComponent, NameDialogComponent } from './../../plugin-components/client-view/client-chat-layout/client-chat-layout.component';
import { CustomMaterialModule } from './../../../custom-material/custom-material.module';
import { AuthGuard } from 'src/app/services/auth.guard';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { AppLayoutComponent } from './app-layout.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import {
  PerfectScrollbarModule, PerfectScrollbarConfigInterface,
  PERFECT_SCROLLBAR_CONFIG
} from 'ngx-perfect-scrollbar';
import { DropZoneDirective } from 'src/app/directives/dropZone.directive';
import { FilestylePipe } from 'src/app/pipes/filestyle.pipe';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { GroupChatCreationComponent } from '../../plugin-components/group-chat-creation/group-chat-creation.component';
import { SettingsComponent } from '../settings/settings.component';
import { AccountComponent } from '../settings/account/account.component';
import { AssignmentComponent } from '../settings/assignment/assignment.component';
import { ControlsComponent } from '../settings/controls/controls.component';
import { ProfileComponent } from '../settings/profile/profile.component';
import { ProjectComponent } from '../project/project.component';
import { ManualComponent, ChatManualDialog, ProjectManualDialog } from '../manual/manual.component';
import { UserInquiriesComponent } from '../user-inquiries/user-inquiries.component';
import { UserInquiriesDetailsComponent } from '../user-inquiries-details/user-inquiries-details.component';
import { ProjectManagementComponent } from '../project-management/project-management.component';
import { AddProjectComponent } from '../add-project/add-project.component';
import { ProjectDetailsComponent } from '../project-details/project-details.component';
import { EditProjectComponent } from '../edit-project/edit-project.component';
import { EditorsComponent } from '../editors/editors.component';
import { EditorsTopComponent } from '../editors-top/editors-top.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true
};

// Define navigation routes
const routes: Routes = [
  { path: 'messages', redirectTo: 'home/messages', pathMatch: 'full' },
  { path: 'scheduling', redirectTo: 'home/scheduling', pathMatch: 'full' },
  { path: 'projects', redirectTo: 'home/projects', pathMatch: 'full' },
  { path: 'manual', redirectTo: 'home/manual', pathMatch: 'full' },
  { path: 'user-inquiries', redirectTo: 'home/user-inquiries', pathMatch: 'full' },
  { path: 'user-inquiries/:id', redirectTo: 'home/user-inquiries/:id', pathMatch: 'full' },
  { path: 'project-management', redirectTo: 'home/project-management', pathMatch: 'full' },
  { path: 'project-management/:id', redirectTo: 'home/project-management/:id', pathMatch: 'full' },
  { path: 'editors', redirectTo: 'home/editors', pathMatch: 'full' },
  { path: 'editors-top/:id', redirectTo: 'home/editors-top/:id', pathMatch: 'full' },
  { path: 'user-inquiries', redirectTo: 'home/add-project', pathMatch: 'full' },
  {
    path: 'home', component: AppLayoutComponent,
    children: [
      { path: '', redirectTo: 'messages', pathMatch: 'full' },
      { path: 'messages', component: ClientChatLayoutComponent },
      { path: 'projects', component: ProjectComponent },
      { path: 'scheduling', component: SchedulingComponent },
      { path: 'manual', component: ManualComponent },
      { path: 'user-inquiries', component: UserInquiriesComponent },
      { path: 'user-inquiries/:id', component: UserInquiriesDetailsComponent },
      { path: 'project-management', component: ProjectManagementComponent },
      { path: 'project-management/:id', component: ProjectDetailsComponent },
      { path: 'add-project', component: AddProjectComponent },
      { path: 'edit-project/:id', component: EditProjectComponent },
      { path: 'editors', component: EditorsComponent },
      { path: 'editors-top/:id', component: EditorsTopComponent }
    ]
  },
  {
    path: 'settings', component: SettingsComponent,
    children: [
      { path: '', redirectTo: 'profile', pathMatch: 'full' },
      { path: 'profile', component: ProfileComponent },
      { path: 'account', component: AccountComponent },
      { path: 'controls', component: ControlsComponent },
      { path: 'assignment', component: AssignmentComponent }
    ]
  }
];

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    CustomMaterialModule,
    SharedModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    RouterModule.forChild(routes),
    FullCalendarModule,
    NgxEmojiPickerModule,
    PerfectScrollbarModule,
    ReactiveFormsModule,
    InputsModule
  ],
  declarations: [
    GroupChatCreationComponent,
    ConfirmationDialogComponent,
    AddToGroupDialogComponent,
    NicknameDialogComponent,
    NameDialogComponent,
    EmailComponent,
    ClientRecipientComponent,
    AppLayoutComponent,
    FilestylePipe,
    DateFormatPipe,
    SchedulingComponent,
    ClientChatLayoutComponent,
    EventDialogComponent,
    UploadTaskComponent,
    ProjectComponent,
    ChatManualDialog,
    ProjectManualDialog,
    
  ],
  entryComponents: [
    GroupChatCreationComponent, ChatManualDialog, ProjectManualDialog,
    AddToGroupDialogComponent, EventDialogComponent, ConfirmationDialogComponent, NameDialogComponent, NicknameDialogComponent, EmailComponent],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    } 
  ],
  exports: [RouterModule, ImageFallbackDirective, RootImageFallbackDirective]
})
export class AppLayoutModule { }
