import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
// import * as PhoneHold from 'src/assets/img/phone_hold.svg';
// import * as Phone from 'src/assets/img/phone_hold2.svg';
// import * as JPhone from 'src/assets/img/Fone.svg';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { GetUsersService } from 'src/app/services/get-users.service';
import { ChatService } from 'src/app/services/chat.service';
import { PerfectScrollbarComponent, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { UserProfile } from 'src/app/utils/user-profile.service';

@Component({
  selector: 'app-group-chat-creation',
  templateUrl: './group-chat-creation.component.html',
  styleUrls: ['./group-chat-creation.component.scss']
})
export class GroupChatCreationComponent implements OnInit, OnDestroy {
  @Input() users: Observable<any>;
  @Output() chatId: string;
  @Output() close = new EventEmitter<boolean>();
  // phone1 = PhoneHold;
  // phone2 = Phone;
  // jPhone = JPhone;
  currentUser;
  currentUSerSubscription: Subscription;
  groupForm = this.fb.group({
    'name': new FormControl('', Validators.required)
  });
  public type = 'component';
  public config: PerfectScrollbarConfigInterface = {};
  @ViewChild(PerfectScrollbarComponent, {static: false}) componentRef?: PerfectScrollbarComponent;
  showList: boolean;
  startAt: BehaviorSubject<string | null> = new BehaviorSubject('');
  group = [] as any;
  get auth() { return this.profile.auth; }
  constructor(
    private profile: UserProfile,
    private cs: ChatService,
    private fb: FormBuilder,
    private getUsersService: GetUsersService) { }

  ngOnInit() {
    this.currentUSerSubscription = this.profile
      .stream().subscribe(
        r => {
          this.currentUser = r;
        }
      );
    this.users = this.getUsersService.getUsers(this.startAt);
  }
  get name() { return this.groupForm.get('name'); }

  submitGroupName(e): void {
    e.preventDefault();
    if (this.groupForm.status === 'VALID') {
      this.showList = true;
    }
  }

  ngOnDestroy(): void {
    if (this.currentUSerSubscription) {
      this.currentUSerSubscription.unsubscribe();
    }

  }
  closeGroup(): void {
    this.close.emit(false);
  }

  search($event): void {
    const q = $event.target.value;
    this.startAt.next(q);
    // this.endAt.next(q + '\uf8ff');
  }

  addUser(u: any): void {
    if (this.group.some(user => user.id === u.id)) {
      console.log("already there: ", u.id)
      return;
    } else {
      this.group.push(u);
      console.log(u)
    }
    
  }

  removeFromGroup(id): void {
    this.group = this.group.filter(user => user.id !== id);
  }

  createGroupChat(): void {
    this.cs.createGroupChat(this.group, this.name.value, this.currentUser);
    this.closeGroup();
  }
}
