import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef, ChangeDetectionStrategy, Inject
} from '@angular/core';
import { ConnectionService } from 'ng-connection-service';
import { Observable, Subscription, BehaviorSubject, of, pipe } from 'rxjs';
import { AngularFireUploadTask, AngularFireStorage } from '@angular/fire/storage';
import { map, filter } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { ChatService } from 'src/app/services/chat.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { GetUsersService } from 'src/app/services/get-users.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { listStagger, fadeInSlideUp } from 'src/app/animations';
import { LinkifyService } from 'src/app/services/linkify.service';
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarComponent
} from 'ngx-perfect-scrollbar';
import { UserProfile } from 'src/app/utils/user-profile.service';
import * as firebase from 'firebase';

export interface NicknameData {
  nickName?: string;
}
@Component({
  selector: 'app-client-chat-layout',
  templateUrl: './client-chat-layout.component.html',
  styleUrls: ['./client-chat-layout.component.scss'],
  animations: [listStagger, fadeInSlideUp],
  // changeDetection: ChangeDetectionStrategy.OnPush
})


export class ClientChatLayoutComponent implements OnInit, OnDestroy, AfterViewChecked, AfterViewInit {
  status = 'ONLINE';
  isConnected = true;

  
  
  task: AngularFireUploadTask;
  files: File[] = [];
  showFiller = false;
  @ViewChild('sidenav2', { static: false }) sidenav;
  @ViewChild('drawer', { static: false }) drawer;
  public type = 'component';
  public config: PerfectScrollbarConfigInterface = {};
  private photo$;
  @ViewChild(PerfectScrollbarComponent, { static: false }) componentRef?: PerfectScrollbarComponent;
  @ViewChild('chatRef', { static: false }) chatRef?: PerfectScrollbarComponent;
  @ViewChild('inputRef', { static: false }) inPutRef?: PerfectScrollbarComponent;
  @ViewChild('inputChat', {static : false}) set bannerNoteRef(inputEl: ElementRef) {
    if (!!inputEl) {
      inputEl.nativeElement.focus();
    }
  };
  users;
  startAt: BehaviorSubject<string | null> = new BehaviorSubject('');
  chat$: Observable<any>;
  chats: any;
  chatId: any;
  newMsg = '';
  chatRooms$: Observable<any>;
  myChats$: Observable<any>;
  myDeletedChats$: Observable<any>;
  currentUser;
  percentage;
  currentReceipient;
  userForm: FormGroup;
  toggled: boolean;
  isCreating: boolean;
  routeSub: Subscription;
  chatSub: Subscription;
  userSub: Subscription;
  profileSub: Subscription;
  user$;
  constructor(
    private profile: UserProfile,
    public dialog: MatDialog, 
    private getUsersService: GetUsersService,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private lS: LinkifyService,
    public cs: ChatService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private storage: AngularFireStorage,
    private afAuth: AngularFireAuth,
    private cdr: ChangeDetectorRef,
    private _snackBar: MatSnackBar,
    private db: AngularFirestore,
    private connectionService: ConnectionService) { 
      this.photo$ = this.profile.stream().pipe(map(profile => !!profile ? profile.photoURL : '')); //console log to check user id
      this.connectionService.monitor().subscribe(isConnected => {
        this.isConnected = isConnected;
        if (this.isConnected) {
          this.status = "ONLINE";
        }
        else {
          this.status = "OFFLINE";
        }
      })

     
    }
// this.productService.getProducts().subscribe(products$ => this.products$ = products$,
//errmess => this.errMess = <any>errmess);
    
  get auth() { return this.profile.auth; }
  ngOnInit(): void {
    this.cs.getHiddenChats().then(a => console.log(a))
    /*
    this.cs.getMyChats(this.profile.uid).subscribe(myChats$ => this.myChats$ = myChats$);
*/
    this.profileSub = this.profile.stream().subscribe(r => {
      this.currentUser = r; console.log(this.currentUser)
      this.myChats$ = this.cs.getMyChats(r.id);
      /*
      this.myDeletedChats$ = this.cs.getMyDeletedMessages(r.id);
      this.myDeletedChats$.subscribe(r => {
     console.log(r)
      })*/

      this.myChats$.subscribe(r => { console.log(r);
        for (let index = 0; index < r.length; index++) {
          console.log(r[index])          
        }
      })

      this.myChats$.subscribe(r => { console.log(r);
        if (this.chatId && !r.some(e => e.id === this.chatId)) {
          this.router.navigate(['/home/messages']);
        }
      })

     /// this.chatRooms$ = this.cs.getChatRooms(r.id);

      /*
      this.chatRooms$.subscribe(r => {
        if (this.chatId && !r.some(e => e.id === this.chatId)) {
          this.router.navigate(['/home/messages']);
        }
      }, console.log);*/
    });
    this.user$ = this.afAuth.auth.currentUser;
    setTimeout(() => {
      this.drawer.open();
    }, 800);
    this.routeSub = this.route.queryParamMap
      .pipe(
        map((params: any) => {
          this.chatId = null;
          this.currentReceipient = null;
          this.chat$ = of(null);
          return params.params.id;
        })
      )
      .subscribe(id => {
        if (id) {
          this.chatId = id;
          this.chat$ = of([]);
          setTimeout(() => {
            const source = this.cs.getChat(id);
            this.chat$ = this.cs.joinUsers(source);
            this.chatSub = this.chat$.subscribe(r => {
              const chatDetails = r.arr || [];
              this.userSub = this.cs.users.subscribe((res) => {
                if (Object.keys(chatDetails).length && res.userDetails && res.userDetails.length) {
                  if (JSON.stringify(chatDetails) !== JSON.stringify(res.userDetails)) {
                    this.cs.updateUserDetailsOnChat(this.chatId, chatDetails);
                  } else {
                    return;
                  }
                }
              });
              setTimeout(() => {
                if (this.type === 'component' && this.chatRef && this.chatRef.directiveRef) {
                  this.chatRef.directiveRef.scrollToBottom();
                }
              }, 100);
            }, console.log);
          }, 200);
          setTimeout(() => {
            this.sidenav.open();
          }, 1500);
        } else {
          this.chatId = null;
          this.currentReceipient = null;
          this.chat$ = of(null); 
          if (typeof this.sidenav !== typeof undefined) {
            this.sidenav.close();
          }
        }
      });
    this.userForm = this.fb.group({
      userInput: null
    });
    
    
    
  }

  ngAfterViewInit(): void {
    this.users = this.getUsersService.getUsers(this.startAt);
  }

  closeDrawers(): void {
    this.drawer.close();
    this.sidenav.close();
  }

  public onScrollEvent(event: any): void {
    // console.log(event);
  }

  closeCreate(event): void {
    this.isCreating = event;
    setTimeout(() => {
      this.drawer.open();
      if (this.chatId) {
        this.sidenav.open();
      }
    }, 1000);
  }

  ngOnDestroy(): void {
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
    if (this.chatSub) {
      this.chatSub.unsubscribe();
    }
    if (this.userSub) {
      this.userSub.unsubscribe();
    }
    if (this.profileSub) {
      this.profileSub.unsubscribe();
    }
  }
  startChat(chatId): void {
    this.cs.startChat(chatId);

  }

  search($event): void {
    const q = $event.target.value;
    this.startAt.next(q);
    // this.endAt.next(q + '\uf8ff');
  }

  break(): void {
    // console.log(this.chatId);
    this.snackBar.open('Please select a user to begin chatting', 'Okay', {
      duration: 600
    });
    return;
  }

deleteChat(id) {
    if (window.confirm('Are you sure you want to delete this chat?')) {
      this.cs.deleteChat(id)
    }
  }

  hideChat(id) {
      this.cs.hideChat(id,this.currentUser.id)
  }
  returnLinkifiedText(text: string): string {
    const l = this.lS.linkifyString(text);
    return typeof l !== typeof undefined ? l : text;
  }

  submit(chatId, photoURL, deetz,history): void {
    console.log(photoURL)
    if (!this.newMsg) {
      this.snackBar.open('You need to enter something.', '', {
        duration: 2000
      });
      setTimeout(() => {
        this.newMsg = '';
      }, 50);
    } else if (!this.isConnected){
      this.snackBar.open('You are offline. Try again later.', '', {
        duration: 2000
      });
    } else {
      let uz;
      for (let i = 0; i < deetz.length; i++){
        if (deetz[i].id == this.currentUser.id){
          uz = deetz[i]
        }
      }
      
      this.cs.sendMessage(this.currentUser.id,this.currentUser.displayName, chatId, this.currentUser.photoURL, this.newMsg, uz,history); 
      setTimeout(() => {
        this.newMsg = '';
      }, 0);
      this.files = []
    }
  }

  ngAfterViewChecked(): void {
  }


  trackByCreated(i: number, msg: any): number | string {
    return msg.createdAt;
  }

  createChat(r_id: any, email: any, displayName?: string, photo?): void {
    this.cs.create(r_id, email, displayName, this.currentUser, photo);

  }

  getDetails(details) {
    return details.filter(e => e.id !== this.currentUser.id);
  }
  onDrop(files: FileList): void {
    if (!this.isConnected){
      this.snackBar.open('You are offline. Try again later.', '', {
        duration: 2000
      });
    } else {
      for (let i = 0; i < files.length; i++) {
        this.files.push(files.item(i));
      }
    }
  }

  addFile(event, deetz,history): void {
    console.log(event)
    console.log(deetz)
    console.log(history)

    if (!this.isConnected){
      this.snackBar.open('You are offline. Try again later.', '', {
        duration: 2000
      });
    } else {
      let uz;
      for (let i = 0; i < deetz.length; i++){
        if (deetz[i].id == this.currentUser.id){
          uz = deetz[i]
        }
      }
      this.cs.sendMessage(this.currentUser.id,this.currentUser.displayName,this.chatId, '', '', uz,history, event);
      
    } 
    this.files = []
  }

  calculatePercentage(event): void {
    this.percentage = event;
  }

  formatDate(date, format): string {
    switch (format) {
      case 'day':
        return moment(date).format('dddd');
      case 'now':
        return moment(date).fromNow();
      case 'calendar':
        return moment(date).calendar();
      case 'L':
        return moment(date).format('L');
      case 'll':
        return moment(date).format('ll');
    } 
  }

  handleSelection(event): void {
    // console.log(event.char);
    this.newMsg += event.char;
  }

  dateDifference(date) {
    const date1 = moment(new Date()).format('YYYY,MM,DD');
    const date2 = moment(date).format('YYYY,MM,DD');
    const a = moment(date1.split(','));
    const b = moment(date2.split(','));
    return a.diff(b, 'days');
  }

  timeDifference(date){
    const date1 = Math.round(new Date().getTime());
    return Math.round((date1 - date)/1000)
  }

  ifExist(recepient) {
  return firebase.database().ref("/users/").child("users")
    .orderByChild("email").equalTo(recepient)
    .once("value", snapshot => {
      if (snapshot.exists()) {
        const userData = snapshot.val();
        console.log("exists!", userData);
        return true;
      }
      return false;
    });
  }

  deleteMessage(key, message,id){
    this.cs.deleteMessage(key, message,id); 
  }

  deleteFile(key, message){
    this.storage.storage.refFromURL(message.downloadUrl).delete();
    this.cs.deleteFile(key, message); 
  }

  changeName(id, nickName){
    
      // tslint:disable-next-line: no-use-before-declare
      const dialogRef = this.dialog.open(NameDialogComponent, {
        width: '400px',
        data: { nickName }
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result.length) {
          this.db.collection('chats').doc(id).update({
            name: result})
          this._snackBar.open(`Chat name changed`, 'Okay', {
            duration: 6000,
          });
        }
      });
  
  }
}

@Component({
  selector: 'app-name-dialog',
  template: `
  <h1 mat-dialog-title class="dialog-title">Enter the chat's new name</h1>
  <div mat-dialog-content>
    <mat-form-field style="font-size: 13px; width: 15vw ;margin-right: 1.4%;" appearance="outline">
      <input matInput [(ngModel)]="data.nickName" cdkFocusInitial />
    </mat-form-field>
  </div>
  <div mat-dialog-actions align="end" class="m-0">
    <button class= "no-focus" mat-button color="default" (click)="onNoClick()">Cancel</button>
    <button class= "no-focus" mat-raised-button color="primary" [mat-dialog-close]="data.nickName" cdkFocusInitial>Ok</button>
  </div>
  `,
  styles: [`
    .dialog-title {
      font-family: 'Poppins', san-serif;
    }
    .no-focus {
      font-family: 'Poppins', san-serif;
      outline: 0
    }
    .context {
      font-family: 'Poppins', san-serif;
      font-size: 13px;
      font-weight: 400
    }
  `]
})
export class NameDialogComponent {
  private nickName: FormControl;
  constructor(
    public dialogRef: MatDialogRef<NameDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: NicknameData) { 
      this.nickName = new FormControl(data, [Validators.required]);
    }

  onNoClick(): void {

    this.dialogRef.close();
  }
}
