import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, Subscription } from 'rxjs';
import { AuthGuard } from 'src/app/services/auth.guard';
import { UserProfile } from 'src/app/utils/user-profile.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { AssignmentService } from 'src/app/services/assignment/assignment.service';
import { ActivatedRoute, Router } from '@angular/router';
declare let Email: any;
import '../../../services/mail/smtp.js'

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit {
  assignmentTracker: Observable<any>; //for assignment tracking admin and lead editors
  dateToday = new Date(Date.now()).toLocaleDateString('sv-SE')

  mySentAssignments: Observable<any>; //for editors and lead editors
  myOnGoingAssignments: Observable<any>; //for editors and lead editors
 
  myAssignmentsE: Observable<any>; //editors
  myCompletedAssignments: Observable<any>; //for editors and lead editors

  myAssignedAssignments: Observable<any>; //for clients
  myAssignedAssignments2: Observable<any>;
  currentUSerSubscription: Subscription;
  currentUser;
  profileSub: Subscription;
  user$;
  
  get auth() { return this.profile.auth; }

  constructor( 
    private profile: UserProfile, 
    private afAuth: AngularFireAuth,
    private as: AssignmentService,
    private afs: AngularFirestore,
    private router: Router,
    ) { }

  ngOnInit() {
    this.currentUSerSubscription = this.profile
      .stream().subscribe(
        r => {
          this.currentUser = r; 
          this.as.getMyCompletedAssignedTasks(r.email).subscribe(myCompletedAssignments => {
            this.myCompletedAssignments = myCompletedAssignments
          })

          this.myAssignedAssignments = this.as.getMyAssignedTasks(r.email); 
          this.myAssignedAssignments.subscribe(myAssignedAssignments2 => {
            this.myAssignedAssignments2 = myAssignedAssignments2;
          })  // code for getting client assigned 
          
          this.mySentAssignments = this.as.getMySentAssignments(r.email); //for getting editor assignments
          this.mySentAssignments.subscribe(myAssignments2 => {
            this.mySentAssignments = myAssignments2;
          }) 

          this.as.getMyOngoingAssignments(r.email).subscribe(myOnGoing => { this.myOnGoingAssignments = myOnGoing})

          this.as.getMyAssignments(r.email).subscribe(myOnGoing => { this.myAssignmentsE = myOnGoing})

          this.as.getAllAssignments().subscribe((assignmentTracker)=> {
            this.assignmentTracker = assignmentTracker,console.log(assignmentTracker);
          });
        }
      );
  }
  deleteAssgn(id) {
    if (window.confirm('Are you sure you want to delete this assignment?')) {
      this.as.deleteAssignment(id)
    }
}

routeToAssgn() {
  this.router.navigate(['/settings/assignment']);
}

acceptAssignment(admin, _id){
  Email.send({
    Host : 'smtp.gmail.com',
    Username : 'gfc.firebase@gmail.com',
    Password : 'yvmtwwbkzikmskdi',
    To : admin,
    From : this.currentUser.email,
    Subject : 'Assignment Accepted',
    Body : `Editor ${this.currentUser.displayName} has accepted work assigned.`
    }).then(
      message => {

        if(message === 'OK')
            alert( 'Successfully Sent!');
        else
          alert('Error: '+ message);
      }
    );
  return this.afs.doc(`assignments/${_id}`).update({status: "On Going",projectStatus: "On Going"});
}

rejectAssignment(admin, _id: string){
  Email.send({
    Host : 'smtp.gmail.com',
    Username : 'gfc.firebase@gmail.com',
    Password : 'yvmtwwbkzikmskdi',
    To : admin,
    From : this.currentUser.email,
    Subject : 'Assignment Accepted',
    Body : `Editor ${this.currentUser.displayName} has rejected work assigned.`
    }).then(
      message => {

        if(message === 'OK')
            alert( 'Successfully Sent!');
        else
          alert('Error: '+ message);
      }
    );
  return this.afs.doc(`assignments/${_id}`).update({status: "Rejected"});
}

finishAssignment(editorAssigned, _id: string){
  Email.send({
    Host : 'smtp.gmail.com',
    Username : 'gfc.firebase@gmail.com',
    Password : 'yvmtwwbkzikmskdi',
    To : editorAssigned,
    From : this.currentUser.email,
    Subject : 'Assignment Accepted',
    Body : `Editor ${this.currentUser.displayName} has completed the assignment!.`
    }).then(
      message => {

        if(message === 'OK')
            alert( 'Successfully Sent!');
        else
          alert('Error: '+ message);
      }
    );
  return this.afs.doc(`assignments/${_id}`).update({status: "Completed",projectStatus: "Completed"});
}

}
