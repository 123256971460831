import { Injectable } from '@angular/core';
import linkifyStr from 'linkifyjs/string';

@Injectable({
  providedIn: 'root'
})
export class LinkifyService {

  constructor() { }

  linkifyString(value: string): any {
    const options = {/* … */ };
    const str = value;
    return linkifyStr(str, options);
  }

}
