import { Component, OnInit, ViewChild, AfterViewChecked, Inject, ViewEncapsulation } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import dayGridPlugin from '@fullcalendar/daygrid';
import { FullCalendarComponent } from '@fullcalendar/angular';
import interactionPlugin from '@fullcalendar/interaction';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { PaletteSettings } from '@progress/kendo-angular-inputs';
import { AssignmentService } from 'src/app/services/assignment/assignment.service';
import { AngularFirestore } from '@angular/fire/firestore';

export interface DialogData {
  date: string;
  info: any;
}

@Component({
  selector: 'app-scheduling',
  templateUrl: './scheduling.component.html',
  styleUrls: ['./scheduling.component.scss'],
})
export class SchedulingComponent implements OnInit, AfterViewChecked {

  @ViewChild('calendar', { static: false }) calendarComponent: FullCalendarComponent;
  calendarPlugins = [dayGridPlugin, interactionPlugin]; // important!
  showCal: boolean;
  assignments: Observable<any>;
  assignments$;
  thisAssgn: Observable<any>;
  myAssgn;
  startAt: BehaviorSubject<string | null> = new BehaviorSubject('');
  assigns: any;
  calendarApi;
  constructor(public dialog: MatDialog, private as: AssignmentService, private afs: AngularFirestore) { }

  ngOnInit(): void {

    setTimeout(() => {
      this.showCal = true;
    }, 500);
    setTimeout(() => {
      this.assignments = this.as.getAssignments(this.startAt);
      this.assignments.subscribe(r => {
        if (r){
          this.assigns = r;
          this.insertEvent(this.assigns)
        }
      }, console.log)
    }, 1000);
   
  }

  ngAfterViewChecked(): void { 

  }

  ngAfterViewInit(): void {
    

  }


  handleDateClick(arg) {
    this.thisAssgn = this.as.getThisAssignment(arg.dateStr);
    this.thisAssgn.subscribe(myAssgn => {
      this.myAssgn = myAssgn
    })
    let assgnDate = this.myAssgn;
    // tslint:disable-next-line: no-use-before-declare
    const dialogRef = this.dialog.open(EventDialogComponent, {
      
      width: '400px',
      data: { 
        date: arg.dateStr, 
        info: this.myAssgn
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  async insertEvent(events){
    
    for (var i = 0; i < events.length; i++){
      let dateNow1 = new Date(Date.now()).toLocaleDateString('sv-SE');  
      if (events[i].status == 'On Going' && dateNow1 < events[i].dueDate){
        this.calendarApi = this.calendarComponent.getApi();

        this.calendarApi.addEvent({ 
          id: events[i].id, 
          title: events[i].taskName, 
          //start: new Date(events[i].date.seconds*1000).toLocaleDateString('sv-SE'), 
          start: new Date(events[i].dueDate).toLocaleDateString('sv-SE'),
          textColor:'white',
          borderColor: 'black',
          extendedProp: {
            client: events[i].clientFname +' '+ events[i].clientLname,
            clientMail: events[i].clientEmail,
            duration: events[i].duration
          } 
        })
      } 

      if (events[i].projectStatus == 'On Going'){
        this.calendarApi = this.calendarComponent.getApi();
        this.calendarApi.addEvent({ 
          id: events[i].id, 
          title: events[i].taskName + " (End of timeline)", 
          //start: new Date(events[i].date.seconds*1000).toLocaleDateString('sv-SE'), 
          start: new Date(events[i].projectTimeline).toLocaleDateString('sv-SE'),
          textColor:'black',
          borderColor: 'black',
          backgroundColor: 'yellow',
          extendedProp: {
            client: events[i].clientFname +' '+ events[i].clientLname,
            clientMail: events[i].clientEmail,
            duration: events[i].duration
          } 
        })
      } 

      let dateNow = new Date(Date.now()).toLocaleDateString('sv-SE');
      if (events[i].status == 'On Going' && dateNow > events[i].dueDate){
        this.calendarApi = this.calendarComponent.getApi();

        this.calendarApi.addEvent({ 
          id: events[i].id, 
          title: events[i].taskName + " (LATE)", 
          //start: new Date(events[i].date.seconds*1000).toLocaleDateString('sv-SE'), 
          start: new Date(events[i].dueDate).toLocaleDateString('sv-SE'),
          textColor:'white',
          borderColor: 'red',
          backgroundColor: 'red',
          extendedProp: {
            client: events[i].clientFname +' '+ events[i].clientLname,
            clientMail: events[i].clientEmail,
            duration: events[i].duration
          } 
        })
      }
    }
  }
}

@Component({  
  selector: 'app-event-dialog',
  template: `
  <h1 mat-dialog-title>Events on {{converDate(data.date, 'MMM Do YYYY')}}</h1>
  <div mat-dialog-content>
    <div *ngIf="myAssgn">
      <div *ngFor="let assgn of myAssgn; let i = index">
        <h6>{{i+1}}.) {{assgn.taskName}} <span *ngIf="dateNow1 > assgn.dueDate">(Late)</span></h6>
        <ul>
          <li>{{assgn.workType}} for {{assgn.clientFname}}</li>
          <li>Assigned Editor: {{assgn.editorAssigned}}</li>
          <li>Date given: {{assgn.date}}</li>
          <li>Deadline: <span style="color:red;font-weight: bold;"
          >{{assgn.dueDate}}</span></li>
          <li>Revisions available until: <span style="color:red;font-weight: bold;"
          >{{assgn.projectTimeline}}</span></li>
        </ul>
      </div>
    </div>
    <div *ngIf="myAssgnTimeline">
      <div *ngFor="let assgn of myAssgnTimeline; let i = index">
        <h6>{{i+1}}.) {{assgn.taskName}} (End of Timeline)</h6>
        <ul>
          <li>{{assgn.workType}} for {{assgn.clientFname}}</li>
          <li>Assigned Editor: {{assgn.editorAssigned}}</li>
          <li>Date given: {{assgn.date}}</li>
        </ul>
      </div>
    </div>
    <h5>Create Event</h5>
    <form [formGroup]="eventForm" (ngSubmit)="onSubmit($event)" style="width:100%">
      <mat-form-field appearance = "fill" class="full-width">
        <mat-label>title</mat-label>
        <input formControlName="title" autocomplete="off" matInput>
        <mat-error *ngIf="eventForm.get('title').hasError('required')">
          <>Title is <strong>required</strong>
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance = "fill" class="full-width">
        <mat-label>description</mat-label>
        <input formControlName="description" autocomplete="off" multiple matInput>
      </mat-form-field>
      <mat-form-field class="full-width" appearance="filled">
        <mat-label>end date</mat-label>
        <input disabled ="true" readonly formControlName="endDate" [min]="data.date" matInput [matDatepicker]="picker">
        <mat-datepicker-toggle min matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker touchUi #picker disabled="false"></mat-datepicker>
      </mat-form-field>
      <div class="wrapper">
        <kendo-colorpicker
          [view]="'palette'"
          [format]="'hex'"
          [paletteSettings]="settings"
          [value]="selected"
          (valueChange)="onChange($event)"
        >
        </kendo-colorpicker>
      </div>
    </form>
  </div>
  <div mat-dialog-actions align="end" style="margin: 0">
    <button mat-stroked-button class="btn-action" color="secondary" (click)="onNoClick()">No Thanks</button>
    <button mat-stroked-button class="btn-action" color="accent" [mat-dialog-close]="" cdkFocusInitial (click)="onSubmit()">Ok</button>
  </div>
  `,
  styles: [`
    .full-width {
      width: 100%
    }
    .btn-action {
      outline: 0;
      margin: 4px;
    }
    .example-wrapper {
      display: flex;
      flex-wrap: wrap;
      min-height: auto;
    }
    .example-wrapper > .k-button {
      font-weight: bold;
      align-self: self-start;
    }
    .example-wrapper form {
      margin-right: 0.333rem;
    }
    .wrapper { position: relative; width: 100%; text-align: center; }
    .k-colorpicker { position: absolute; right: 0 }
  `],
  encapsulation: ViewEncapsulation.None
})
export class EventDialogComponent implements OnInit {
  eventForm: FormGroup;
  public selected = '#fe413b';
  public palette: Array<string> = ['#fe413b', '#4eaec5', '#ff5500', '#42b683'];

  public kendoColors: any = {
    '#fe413b': 'Kendo UI for Angular',
    '#4eaec5': 'KendoReact',
    '#ff5500': 'Kendo UI for jQuery',
    '#42b683': 'Kendo UI for Vue'
  };

  public settings: PaletteSettings = {
    palette: this.palette,
    tileSize: 30
  };
  constructor(
    private as: AssignmentService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<EventDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }
    dateNow1 = new Date(Date.now()).toLocaleDateString('sv-SE');  
    thisAssgn: Observable<any>;
    thisAssgnTimeline: Observable<any>;
    myAssgn;
    myAssgnTimeline;
  ngOnInit(): void {
    this.thisAssgn = this.as.getThisAssignment(this.data.date);
    this.thisAssgnTimeline = this.as.getThisAssignmentTimeline(this.data.date);
    this.thisAssgnTimeline.subscribe(myAssgnTimeline => {
      this.myAssgnTimeline = myAssgnTimeline, console.log(this.myAssgnTimeline)
    })
    this.thisAssgn.subscribe(myAssgn => {
      this.myAssgn = myAssgn, console.log(this.myAssgn)
    })
    this.eventForm = this.fb.group({
      'title': new FormControl('', [Validators.required]),
      'description': new FormControl(''),
      'endDate': new FormControl(new Date())
    });
    console.log(this.data.date)
    
  }
  onSubmit() {
    //e.preventDefault();
    console.log(this.eventForm.value);
  }

  converDate(date, format) {
    return moment(date).format(format);
  }
  onNoClick(): void {
    this.dialogRef.close();
  }



  public onChange(color: string): void {
    this.selected = color;
  }

}
