import { Router } from '@angular/router';
import { Component, OnInit, AfterViewChecked } from '@angular/core';
import { AuthGuard } from 'src/app/services/auth.guard';
import { AuthService } from 'src/app/services/auth/auth.service';
import { User } from 'firebase';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit, AfterViewChecked {

  get authenticated() { return this.guard.authenticated; }

  private get auth(): AuthService { return this.guard.auth; }

  private get user(): User { return this.auth.user; }

  public get emailVerified(): boolean { return !!this.user && this.user.emailVerified; }

  constructor(readonly guard: AuthGuard, private route: Router) { }

  ngOnInit(): void { }

  ngAfterViewChecked(): void {
    if (this.authenticated) {
      // if (this.emailVerified){
      //   this.route.navigate(['/home/messages']);
      // } else {
      //   this.route.navigate(['/settings/account'])
      // }
      this.route.navigate(['/home/messages']);
    }
  }
}
