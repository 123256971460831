import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filestyle'
})
export class FilestylePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    // console.log(value);
    let icon;
    switch (value) {
      case 'image/png':
        icon = 'photo';
        break;
      case 'application/pdf':
        icon = 'picture_as_pdf';
        break;
      // case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      //   icon = 'picture_as_pdf';
      //   break;
      // case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      //   icon = 'picture_as_pdf';
      //   break;
      default:
        icon = 'insert_drive_file';
        break;
    }
    return icon;
  }

}
