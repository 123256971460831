import { Component, OnInit, Input, Inject, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChatService } from 'src/app/services/chat.service';
import { Observable, of, BehaviorSubject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { fadeInSlideUp, slideInRight } from 'src/app/animations';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { MatSnackBar } from '@angular/material';
import { PerfectScrollbarComponent, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { GetUsersService } from 'src/app/services/get-users.service';
import { UserProfile } from 'src/app/utils/user-profile.service';
import { FormControl, Validators } from '@angular/forms';
export interface DialogData {
  displayName?: string;
}

export interface NicknameData {
  nickName?: string;
}

export interface EmailData {
  email?: string;
}
export interface AddDialogData {
  userDetails?: any;
}

@Component({
  selector: 'app-client-recipient',
  templateUrl: './client-recipient.component.html',
  styleUrls: ['./client-recipient.component.scss'],
  animations: [fadeInSlideUp, slideInRight]
})
export class ClientRecipientComponent implements OnInit, OnDestroy {
  @Input() chatId;
  @Input() displayName;
  public type = 'component';
  panelOpenState = false;
  showInfo: boolean;
  users$: any;
  chat$: Observable<any>;
  chats: any;
  files: any;
  currentUser;
  currentReceipient;
  filesSub: Subscription;
  usersSub: Subscription;
  userSub: Subscription;
  chatSub: Subscription;
  routeSub: Subscription;
  currentUserSub: Subscription;
  get auth() { return this.profile.auth; }

  constructor(
    private profile: UserProfile,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute, private cs: ChatService, private afAuth: AngularFireAuth) {
  }
  ngOnInit() {
    this.currentUserSub = this.profile.stream().subscribe(r => {
      this.currentUser = r;
    });
    this.usersSub = this.cs.users.subscribe(r => this.users$ = r);
    this.filesSub = this.cs.files.subscribe(r => this.files = r);
    this.routeSub = this.route.queryParamMap
      .pipe(
        map((params: any) => {
          this.chatId = null;
          this.currentReceipient = null;
          this.chat$ = of(null);
          return params.params.id;
        })
      )
      .subscribe(id => {
        if (id) {
          this.chatId = id;
          this.chat$ = of([]);
          setTimeout(() => {
            const source = this.cs.getChat(id);
            this.chat$ = this.cs.joinUsers(source);
            this.chatSub = this.chat$.subscribe(r => {
              const chatDetails = r.arr || [];
              this.userSub = this.cs.users.subscribe((res) => {
                if (Object.keys(chatDetails).length && res.userDetails && res.userDetails.length) {
                  if (JSON.stringify(chatDetails) !== JSON.stringify(res.userDetails)) {
                    this.cs.updateUserDetailsOnChat(this.chatId, chatDetails);
                  } else {
                    return;
                  }
                }
              });
            }, console.log);
          }, 200);
        } else {
          this.chatId = null;
          this.currentReceipient = null;
          this.chat$ = of(null);
        }
      });
  }

  ngOnDestroy(): void {
    if (this.filesSub) {
      this.filesSub.unsubscribe();
    }
    if (this.usersSub) {
      this.usersSub.unsubscribe();
    }
  }
  removeUser(id, uid,user, displayName): void {
    // tslint:disable-next-line: no-use-before-declare
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: { displayName }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'yes') {
        this.cs.removeUser(id, uid, user, displayName).then(r => {
          this.users$.userDetails = this.users$.userDetails.filter(user => user.id !== uid);
          this._snackBar.open(`User was succesfully removed`, 'Okay', {
            duration: 6000,
          });
        });
      }
    });
    //this.cs.removeUser(id, uid, this.users$.userDetails, displayName);
  }

  showNickname(id, uid, nickName, user): void {
    let oldNickname = nickName
    // tslint:disable-next-line: no-use-before-declare
    const dialogRef = this.dialog.open(NicknameDialogComponent, {
      width: '500px',
      data: { nickName, user }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.length) {
        this.users$.userDetails.filter(u => u.id !== uid).concat(result);
         this.cs.updateUserNickname(id,this.currentUser,result, oldNickname)
     
        
        this._snackBar.open(`Nickname changed`, 'Okay', {
          duration: 6000,
        });
      }
    });
    //this.cs.removeUser(id, uid, this.users$.userDetails, displayName);
  }

  showEmail(id, uid, email, user): void {
    // tslint:disable-next-line: no-use-before-declare
    const dialogRef = this.dialog.open(EmailComponent, {
      width: '500px',
      data: { email }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.length) {
        this.cs.updateEmail(id, user, result)
        this.users$.userDetails.filter(u => u.id !== uid).concat(result);
        this._snackBar.open(`Email changed`, 'Okay', {
          duration: 6000,
        });
      }
    });
    //this.cs.removeUser(id, uid, this.users$.userDetails, displayName);
  }

  sendMessage(r_id, r_email, displayName, photo): void {
    this.cs.create(r_id, r_email, displayName, this.currentUser, photo);
  }



  addParticipants(): void {
    // tslint:disable-next-line: no-use-before-declare
    const dialogRef = this.dialog.open(AddToGroupDialogComponent, {
      width: '400px',
      data: { userDetails: this.users$.userDetails }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.length) {
        const uids = result.map(user => user.id);
        this.cs.addUser(this.users$.id, uids, this.users$.userDetails, result).then(r => {
          this.users$.userDetails.concat(result);
          this._snackBar.open(`Succesfully Added`, 'Okay', {
            duration: 6000,
          });
          console.log(this.users$.id, uids, this.users$.userDetails, result)
        });
      }
    });

  }



}

@Component({
  selector: 'app-nickname-dialog',
  template: `
  <h1 mat-dialog-title class="dialog-title">Enter your new nickname</h1>
  <div mat-dialog-content>
    <p class="context">This nickname is for this chat only.</p>
    <mat-form-field style="font-size: 13px; width: 15vw ;margin-right: 1.4%;" appearance="outline">
      <input matInput [(ngModel)]="data.nickName" cdkFocusInitial />
    </mat-form-field>
  </div>
  <div mat-dialog-actions align="end" class="m-0">
    <button class= "no-focus" mat-button color="default" (click)="onNoClick()">Cancel</button>
    <button class= "no-focus" mat-raised-button color="primary" [mat-dialog-close]="data.nickName" cdkFocusInitial>Ok</button>
  </div>
  `,
  styles: [`
    .dialog-title {
      font-family: 'Poppins', san-serif;
    }
    .no-focus {
      font-family: 'Poppins', san-serif;
      outline: 0
    }
    .context {
      font-family: 'Poppins', san-serif;
      font-size: 13px;
      font-weight: 400
    }
  `]
})
export class NicknameDialogComponent {
  private nickName: FormControl;
  constructor(
    public dialogRef: MatDialogRef<NicknameDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: NicknameData) { 
      this.nickName = new FormControl(data, [Validators.required]);
    }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-email-dialog',
  template: `
  <h1 mat-dialog-title class="dialog-title">Enter your new email</h1>
  <div mat-dialog-content>
    <p class="context">This email is for this chat only.</p>
    <mat-form-field style="font-size: 13px; width: 15vw ;margin-right: 1.4%;" appearance="outline">
      <input matInput [(ngModel)]="data.email" cdkFocusInitial />
    </mat-form-field>
  </div>
  <div mat-dialog-actions align="end" class="m-0">
    <button class= "no-focus" mat-button color="default" (click)="onNoClick()">Cancel</button>
    <button class= "no-focus" mat-raised-button color="primary" [mat-dialog-close]="data.email" cdkFocusInitial>Ok</button>
  </div>
  `,
  styles: [`
    .dialog-title {
      font-family: 'Poppins', san-serif;
    }
    .no-focus {
      font-family: 'Poppins', san-serif;
      outline: 0
    }
    .context {
      font-family: 'Poppins', san-serif;
      font-size: 13px;
      font-weight: 400
    }
  `]
})
export class EmailComponent {
  private email: FormControl;
  constructor(
    public dialogRef: MatDialogRef<NicknameDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EmailData) { 
      this.email = new FormControl(data, [Validators.required]);
    }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-confirmation-dialog',
  template: `
  <h1 mat-dialog-title class="dialog-title">Remove User</h1>
  <div mat-dialog-content>
    <p class="context">Are you sure you want to remove <b>{{data?.displayName}}</b> from chat group?</p>
  </div>
  <div mat-dialog-actions align="end" class="m-0">
    <button class= "no-focus" mat-button color="default" (click)="onNoClick()">No Thanks</button>
    <button class= "no-focus" mat-raised-button color="primary" [mat-dialog-close]="'yes'" cdkFocusInitial>Yes</button>
  </div>
  `,
  styles: [`
    .dialog-title {
      font-family: 'Poppins', san-serif;
    }
    .no-focus {
      font-family: 'Poppins', san-serif;
      outline: 0
    }
    .context {
      font-family: 'Poppins', san-serif;
      font-size: 13px;
      font-weight: 400
    }
  `]
})
export class ConfirmationDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-add-to-group-dialog',
  template: `
  <h1 mat-dialog-title class="dialog-title">Add User</h1>
  <div mat-dialog-content>
  <mat-form-field appearance="outline" color="primary" appearance="outline" class="w-100 group-name">
      <mat-label>Search user</mat-label>
      <input (keydown)="search($event)" type="text" matInput [matAutocomplete]="auto" placeholder="Search by email"/>
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option style="line-height: unset; height: 66px;" *ngFor="let user of users | async"
          [value]="user?.email" (click)="addUser(user)">
          <span class="d-flex flex-column align-items-start">
            <span class="mat-caption text-muted" style="font-weight: bolder; font-family: 'Poppins', sans-serif;"
              *ngIf="user?.displayName">
              {{ user?.displayName }}
            </span>
            <!-- <span class="mat-caption">{{ user?.uid }}</span> -->
            <small style=" color:#2a4792; font-family: 'Poppins', sans-serif;">
            {{ user?.email }}
            </small>
          </span>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <div *ngIf="group.length" class="group-list">
      <perfect-scrollbar appDropZone *ngIf="type === 'component'" style="position: relative;" fxFlex="auto"
        [config]="config" [scrollIndicators]="true">
        <div style="padding: 14px">
          <div style="margin-bottom: 8px;" class="group-user" *ngFor="let item of group">
            <span class="user-info">
              <span class="user-name">{{ item?.displayName }}</span>
              <span class="user-email">{{ item?.email }}</span>
            </span>
            <mat-icon (click)="removeFromGroup(item?.uid)" class="cancel-icon-button" matRippleColor="rgba(255,255,255,.3)" matRipple>
              cancel
            </mat-icon>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
  <div mat-dialog-actions align="end" class="m-0">
    <button class= "no-focus" mat-button color="default" (click)="onNoClick()">No Thanks</button>
    <button class= "no-focus" mat-raised-button color="primary" [mat-dialog-close]="group" cdkFocusInitial>Add Participants</button>
  </div>
  `,
  styles: [`
    .dialog-title {
      font-family: 'Poppins', san-serif;
    }
    .no-focus {
      font-family: 'Poppins', san-serif;
      outline: 0
    }
    .context {
      font-family: 'Poppins', san-serif;
      font-size: 13px;
      font-weight: 400
    }
    .group-name {
      font-size: 12px;
      width: 100%;
      font-family: "Poppins", sans-serif;
    }
    .group-list {
      background: white;
      border-radius: 6px;
      height: 200px;
      overflow: auto;
      margin-bottom: 16px;
    }
    .group-user {
        border-radius: 8px;
        display: flex;
        flex-flow: row;
        align-items: flex-start;
        justify-content: space-between;
        background: #f5f8fb;
        border-radius: 8px;
        padding: 8px;
    }
    .user-name {
      font-family: "Poppins", sans-serif;
      font-size: 14px;
      font-weight: bolder;
      display: block;
    }
    .user-email {
      font-family: "Poppins", sans-serif;
      font-size: 12px;
    }
    .cancel-icon-button {
      border-radius: 50%;
      float: right;
      cursor: pointer;
      color: #1b47db;
      user-select: none;
      -moz-user-select: -moz-none;
      -webkit-user-select: none;
      -ms-user-select: none;
    }
  `]
})
export class AddToGroupDialogComponent implements OnInit {
  @Input() users: Observable<any>;
  group = [] as any;
  startAt: BehaviorSubject<string | null> = new BehaviorSubject('');
  public type = 'component';
  public config: PerfectScrollbarConfigInterface = {};
  @ViewChild(PerfectScrollbarComponent, {static: false}) componentRef?: PerfectScrollbarComponent;

  constructor(
    private getUsersService: GetUsersService,
    public dialogRef: MatDialogRef<AddToGroupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddDialogData) { }


  ngOnInit() {
    this.users = this.getUsersService.getUsers(this.startAt);
  }

  search($event): void {
    const q = $event.target.value;
    this.startAt.next(q);
  }

  addUser(u: any): void {
    console.log(this.data)
    console.log(typeof this.data)
    if (this.group.some(user => user.email === u.email)) {
      console.log('already exists')
    } 
    
    else {
      const user = u;
      this.group.push(user);
      console.log(this.group)
    }
  }

  removeFromGroup(uid): void {
    this.group = this.group.filter(user => user.uid !== uid);
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}

