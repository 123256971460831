import { Component, OnInit,Inject } from '@angular/core';
import { BehaviorSubject, Observable,Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UserProfile } from 'src/app/utils/user-profile.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { map } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { GetUsersService } from 'src/app/services/get-users.service';
import {MatExpansionModule} from '@angular/material/expansion';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl, Validators } from '@angular/forms';
import { AssignmentService } from 'src/app/services/assignment/assignment.service.js';

export interface ReplyData {
  from?: string;
  content?: string;
  reply?: string;
  subject?: string
  email?:string;
  id?:string;
}
@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {
  panelOpenState = false;
  items$: Observable<any[]>;
  messages$;
  startAt: BehaviorSubject<string | null> = new BehaviorSubject('');
  user$;
  currentUser;
  private get auth(): AuthService { return this.profile.auth; }
  currentUSerSubscription: Subscription;
  constructor(private profile: UserProfile,
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private getUsersService: GetUsersService,
    public dialog: MatDialog,
    private as: AssignmentService) { }

  ngOnInit() {
    this.currentUSerSubscription = this.profile
    .stream().subscribe(
      r => {
        this.currentUser = r; console.log(this.currentUser)
      }
    );
    
  this.user$ = this.afAuth.auth.currentUser;
  //this.items$ = this.afs.collection('adminReplies').valueChanges();

  //this.items$ = this.afs.collection('adminReplies', ref => ref.where('email','==',this.user$.email)).valueChanges();
  this.items$ = this.getUsersService.getAdminMessages(this.startAt,this.user$.email)

  //this.items$ = this.getUsersService.getAdminReply(this.startAt,this.user$.email)
  }
  deleteMessage(from,msg,id){
    console.log(from,msg,id);
    this.as.deleteMessage(from,msg,id)
  }
  reply(from, subject, email,id,message){
    const dialogRef = this.dialog.open(ReplyDialogComponent2, {
      width: '500px',
      data: { from, message: '', subject, email, id }
    });

    dialogRef.afterClosed().subscribe(result => {
        console.log(result)
    });
  }
}

@Component({
  selector: 'app-reply-dialog',
  template: `
  <h1 mat-dialog-title class="dialog-title">Reply to message</h1>
  <div mat-dialog-content>
    <p> Subject: {{data.subject}} </p>
    <p> From: {{data.from}} </p>
    <p> Reply: </p>
    <mat-form-field style="font-size: 13px; width: 25vw ;margin-right: 1.4%;" appearance="outline">
      <textarea matInput placeholder="Enter reply here..." style="width:100%; max-width:100%;" [(ngModel)]="data.reply" cdkFocusInitial> </textarea>
    </mat-form-field>
  </div>
  <div mat-dialog-actions align="end" class="m-0">
    <button class= "no-focus" mat-button color="default" (click)="onNoClick()">Cancel</button>
    <button class= "no-focus" mat-raised-button color="primary" (click)="replyAdmin(data?.from, data?.reply,data?.email,data?.subject,data?.id)" cdkFocusInitial>Send</button>
  </div>
  `,
  styles: [`
    .dialog-title {
      font-family: 'Poppins', san-serif;
    }
    .no-focus {
      font-family: 'Poppins', san-serif;
      outline: 0
    }
    .context {
      font-family: 'Poppins', san-serif;
      font-size: 13px;
      font-weight: 400
    }
  `]
})
export class ReplyDialogComponent2 {
  private reply: FormControl;
  constructor(
    public dialogRef: MatDialogRef<ReplyDialogComponent2>,
    @Inject(MAT_DIALOG_DATA) public data: ReplyData,private as: AssignmentService) { 
      this.reply = new FormControl(data, [Validators.required]);
      console.log(this.data.id)
    }

  onNoClick(): void {

    this.dialogRef.close();
  }

  replyAdmin(from, subject, message,email,id) {
    from = this.data.from;
    subject = this.data.subject;
    message = this.data.reply;
    email = this.data.email;
    id = this.data.id;
    this.as.replyMessageAdmin(from, message, email,subject,id).then(() => {
      this.dialogRef.close();
    })
  }
}


