import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  transform(value: any): any {
    const date1 = moment(new Date()).format('YYYY,MM,DD');
    const date2 = moment(value).format('YYYY,MM,DD');
    const a = moment(date1.split(','));
    const b = moment(date2.split(','));
    const difference = a.diff(b, 'days');
    const format = difference > 2 ? 'll' : 'now';
    switch (format) {
      // case 'day':
      //   return moment(value).format('dddd');
      case 'now':
        return moment(value).fromNow();
      // case 'calendar':
      //   return moment(value).calendar();
      // case 'L':
      //   return moment(value).format('L');
      case 'll':
        return moment(value).format('ll');
      default:
        return '';
    }
  }

}
