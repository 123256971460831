import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { GetUsersService } from 'src/app/services/get-users.service';
import { Params, ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-editors-top',
  templateUrl: './editors-top.component.html',
  styleUrls: ['./editors-top.component.scss']
})
export class EditorsTopComponent implements OnInit {
  editors: any;
  editorList: any;
  startAt: BehaviorSubject<string | null> = new BehaviorSubject('');
  public id: string;
  selectedValue: string;

  constructor(
    private getUsersService: GetUsersService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    if (this.id == 'completed') {
      this.getUsersService.getTop10CompletedEditorsDescending(this.startAt).subscribe((res) => {
        this.editors = res;
      })
    }
    if (this.id == 'total') {
      this.getUsersService.getTopTotalEditors(this.startAt).subscribe((res) => {
        this.editors = res;
      })
    }
    if (this.id == 'late') {
      this.getUsersService.getTop10TotalEditorsDescending(this.startAt).subscribe((res) => {
        this.editors = res;
      })
    }
  }

  test() {
    if (this.selectedValue == 'top10totalasc') {
      this.getUsersService.getTop10TotalEditorsAscending(this.startAt).subscribe((res) => {
        this.editors = res;
      })
    } else if (this.selectedValue == 'top10totaldesc') {
      this.getUsersService.getTop10TotalEditorsDescending(this.startAt).subscribe((res) => {
        this.editors = res;
      })
    } else if (this.selectedValue == 'top10completeddesc') {
      this.getUsersService.getTop10CompletedEditorsDescending(this.startAt).subscribe((res) => {
        this.editors = res;
      })
    } else if (this.selectedValue == 'top10completedasc') {
      this.getUsersService.getTop10CompletedEditorsAscending(this.startAt).subscribe((res) => {
        this.editors = res;
      })
    } else if (this.selectedValue == 'top10latedesc') {
      this.getUsersService.getTop10LateEditorsDescending(this.startAt).subscribe((res) => {
        this.editors = res;
      })
    } else if (this.selectedValue == 'top10lateasc') {
      this.getUsersService.getTop10LateEditorsAscending(this.startAt).subscribe((res) => {
        this.editors = res;
      })
    }
  }

}
