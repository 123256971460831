import { RootImageFallbackDirective } from './directives/rootImageDirective.directive';
//import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; 
import { AppLayoutModule } from './components/layout-components/app-layout/app-layout.module';
import { LandingPageModule } from './components/layout-components/landing-page/landing-page.module';
import { SettingsModule } from './components/layout-components/settings/settings.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import 'flatpickr/dist/flatpickr.css';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CustomMaterialModule } from './custom-material/custom-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { ClientNavigationComponent, DialogElementsExampleDialog } from './components/plugin-components/client-view/client-navigation/client-navigation.component';
import {
  ClientRecipientComponent,
  ConfirmationDialogComponent,
  AddToGroupDialogComponent
} from './components/plugin-components/client-view/client-recipient/client-recipient.component';
import { AngularFireModule } from '@angular/fire';
import { AlertModule } from 'src/app/components/_alert'
import { environment } from '../environments/environment';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { FilestylePipe } from './pipes/filestyle.pipe';
import { SelectProfileComponent } from './components/select-profile/select-profile.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatNativeDateModule } from '@angular/material/core';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ContainerDirective } from './directives/container.directive';
import { NgxEmojiPickerModule } from 'ngx-emoji-picker';
import { GroupChatCreationComponent } from './components/plugin-components/group-chat-creation/group-chat-creation.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NotFoundComponent } from './components/layout-components/not-found/not-found.component';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { LoginComponent } from './components/layout-components/login/login.component';
import { Routes, RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { AlertComponent } from './components/_alert/alert.component';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { ManualComponent } from './components/layout-components/manual/manual.component';
import { UserInquiriesComponent } from './components/layout-components/user-inquiries/user-inquiries.component';
import { UserInquiriesDetailsComponent } from './components/layout-components/user-inquiries-details/user-inquiries-details.component';
import { ProjectManagementComponent } from './components/layout-components/project-management/project-management.component';
import { AddProjectComponent } from './components/layout-components/add-project/add-project.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatChipsModule} from '@angular/material/chips';
import { ProjectDetailsComponent } from './components/layout-components/project-details/project-details.component';
import { EditProjectComponent } from './components/layout-components/edit-project/edit-project.component';
import { ChartModule } from 'angular2-chartjs';
import { EditorsComponent } from './components/layout-components/editors/editors.component';
import {MatTableModule} from '@angular/material/table';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { EditorsTopComponent } from './components/layout-components/editors-top/editors-top.component';


const routes: Routes = [
  { path: 'not-found', component: NotFoundComponent },

  { path: '', redirectTo: 'login', pathMatch: 'full' },

  { path: '**', redirectTo: 'not-found', pathMatch: 'full' }

];

@NgModule({
  declarations: [
    AppComponent,
    // ImageFallbackDirective,
    SelectProfileComponent,
    LoginComponent,
    NotFoundComponent,
    ClientNavigationComponent,
    ManualComponent,
    DialogElementsExampleDialog,
    UserInquiriesComponent,
    UserInquiriesDetailsComponent,
    ProjectManagementComponent,
    AddProjectComponent,
    ProjectDetailsComponent,
    EditProjectComponent,
    EditorsComponent,
    EditorsTopComponent
  ],
  entryComponents: [
    LoginComponent,
    DialogElementsExampleDialog
  ],
  imports: [
    //NgIdleKeepaliveModule.forRoot(),
    AlertModule,
    AppLayoutModule,
    FlexLayoutModule,
    SettingsModule,
    MatChipsModule,
    LandingPageModule,
    BrowserModule,
    MatExpansionModule,
    NgbModule,
    BrowserAnimationsModule,
    FormsModule,
    MatNativeDateModule,
    HttpClientModule,
    ReactiveFormsModule,
    CustomMaterialModule,
    ChartModule,
    MatTableModule,
    Ng2SearchPipeModule,
    MaterialFileInputModule,
    RouterModule.forRoot(routes),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
    AngularFireStorageModule, InputsModule,
    AngularFireFunctionsModule,
    NgxEmojiPickerModule.forRoot()
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
