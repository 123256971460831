import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { AuthService } from './auth/auth.service';
import { map, switchMap, filter, tap } from 'rxjs/operators';
import { firestore } from 'firebase';
import { Observable, combineLatest, of, Subject, BehaviorSubject, Subscription } from 'rxjs';
import { FirebaseAuth } from '@angular/fire';
import { AngularFireAuth } from '@angular/fire/auth';
import { URL } from '../components/plugin-components/client-view/upload-task/upload-task.component';
import * as _ from 'lodash';
import { MatSnackBar } from '@angular/material';
import * as firebase from 'firebase';
import { UserProfile } from '../utils/user-profile.service';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  users$ = new BehaviorSubject<any>({});
  users = this.users$.asObservable();
  currentUser$ = new BehaviorSubject<[]>([]);
  currentUser;
  files$ = new BehaviorSubject<[]>([]);
  files = this.files$.asObservable();

  constructor(
    private afs: AngularFirestore,
    private auth: AuthService,
    private router: Router,
    private fsAuth: AngularFireAuth,
    private _snackBar: MatSnackBar,
    private profile: UserProfile
  ) { }

  profileSub: Subscription;
  user$;
  
  nextUser(user): void {
    this.currentUser$.next(user);
  }
  
  ngOnInit(): void{
    this.profileSub = this.profile.stream().subscribe(r => {
      this.currentUser = r;
    });
    this.user$ = this.fsAuth.auth.currentUser;
    console.log(this.user$, "Heh")
  }

  getChat(chatId) {
    return this.afs.collection('chats')
      .doc(chatId)
      .snapshotChanges()
      .pipe(
        map((doc: any) => {
          // console.log(doc)
          return { id: doc.payload.id, ...doc.payload.data() };
        })
      );
  }
  // getFiles(chatId) {
  //   const sString = chatId.split('_');
  //   const secondaryId = `${sString[1]}_${sString[0]}`;
  //   const fileRef = this.afs.collection('files', ref => (ref.where('chatId', '==', chatId)));
  //   const secondRef = this.afs.collection('files', ref => (ref.where('chatId', '==', secondaryId)));
  //   const fileRef$ = new Subject();
  //   const secondRef$ = new Subject();
  //   // tslint:disable-next-line: deprecation
  //   return combineLatest(fileRef.valueChanges(),
  //     secondRef.valueChanges())
  //     .pipe(switchMap(uploads => {
  //       // const [files, secondFiles] = uploads;
  //       // const combined = files.concat(secondFiles);
  //       return of(uploads);
  //     }));
  // }

  getMyChats(uid) { //will add orderby (not working for some reason same with getChatRooms)
    return this.afs.collection('chats', ref => 
    ref.where('members','array-contains',uid).orderBy('lastModified','desc')).snapshotChanges().pipe(
      map((changes: any) => {
        return changes.map(
          c => {
            const data = c.payload.doc.data();
            const id = c.payload.doc.id;
            return { id, ...data };
          }
        )
      })
    );
  }

  /*
  getHiddenChats() { //will add orderby (not working for some reason same with getChatRooms)
    return this.afs.collection('chats', ref => 
    ref.where('status','==','Hidden').orderBy('lastModified','asc').limit(10)).snapshotChanges().pipe(
      map((changes: any) => {
        return changes.map(
          c => {
            const id = c.payload.doc.id;
            return { id };
          }
        )
      })
    );
  }*/
  getHiddenChats() { 
    
    return this.afs.collection('chats', ref => 
    ref.where('status','==','Hidden').orderBy('lastModified','asc').limit(10)).get().toPromise()
    .then(a => {
      const container = []
      a.forEach(doc => {
        container.push(doc.ref.id);
      });
        return Promise.all(container)
    })
  }

  getMyDeletedMessages(uid) { //will add orderby (not working for some reason same with getChatRooms)
    return this.afs.collection('chats', ref => 
    ref.where('deletedMessages','array-contains',uid).orderBy('lastModified','desc')).snapshotChanges().pipe(
      map((changes: any) => {
        return changes.map(
          c => {
            const data = c.payload.doc.data();
            const id = c.payload.doc.id;
            return { id, ...data };
          }
        )
      })
    );
  }
//afs.collection('items', ref => ref.where('size', '==', 'large'))
/*
  getChatRooms(uid) {
    return this
      .afs.collection('chats', ref =>
        ref.orderBy(uid).orderBy('createdAt', 'desc').startAt(true).endAt(true)
      )
      .snapshotChanges()
      .pipe(
        map((changes: any) => {
          return changes.map(
            c => {
              const data = c.payload.doc.data();
              const id = c.payload.doc.id;
              return { id, ...data };
            }
          )
        })
      );
  }
*/
  async create(r_id: any, r_email, r_name: string, user, photo) {
    console.log(r_id)
    console.log(r_email)
    console.log(r_name)
    console.log(user)
    console.log(photo)
    const { id, email, displayName, photoURL } = user;
  
    const data = {
      [id]: true,
      [r_id]: true,
      createdAt: Date.now(),
      lastModified: Date.now(),
      creator: id,
      status: 'Active',
      type: 'personal chat',
      members: [
        id,
        r_id
      ],
      history: [
        id,
        r_id
      ],
      //u_id: id,
      //r_id: r_id,
      userDetails: [
        {
          email,
          id,
          nickName: user.displayName
        },
        {
          email: r_email,
          id: r_id,
          nickName: r_name
        }
      ],
      count: 0,
      messages: []
    };
    const dRef = this.afs.collection('chats');
    if (id && r_id) {
      const userRef = this.afs.collection('chats', ref => {
        return ref.where(`${id}`, '==', true)
          .where(`${r_id}`, '==', true)
          .where('type', '==', 'personal chat');
      })
        .snapshotChanges()
        .pipe(
          map(
            (changes: any) => {
              return changes.map(
                c => {
                  const d = c.payload.doc.data();
                  const id = c.payload.doc.id;
                  return { id, ...d };
                }
              );
            }
          )
        )
        .subscribe(
          doc => {
            if (doc.length && doc[0].id) {
              // console.log('exist');
              this.router.navigate(['/home/messages'], { queryParams: { id: `${doc[0].id}` } });
              console.log(doc[0].id)
            } else {
              // console.log('not exist');
              setTimeout(async () => {
                const docRef = await this.afs.collection('chats').add(data);
                this.router.navigate(['/home/messages'], { queryParams: { id: docRef.id } });
              }, 400);
              
            }
          }
        );
    }
    console.log('create chat')
  }

  async createGroupChat(users, name, user) {
    const { id, email, displayName: dName, photoURL } = user;
    let u = [];

    const data = {
      [id]: true,
      createdAt: Date.now(),
      lastModified: Date.now(),
      name,
      count: 0,
      /*
      members: [
        id
      ],
      */
      messages: [],
      creator: id,
      status: 'Active',
      type: 'group chat',
      members: (() => {
         const id = [
           user.id,
         ];

        users.forEach((i) => {
          id.push(
            i.id
          )
        })
        return id.concat(u);
      })(),
      history: (() => {
        const id = [
          user.id,
        ];

       users.forEach((i) => {
         id.push(
           i.id
         )
       })
       return id.concat(u);
     })(),
      
      userDetails: (() => {
        const deets = [
          {
            email,
            id,
            nickName: dName
          }
        ];

        users.forEach((i) => {
          u.push({
            email: i.email,
            id: i.id,
            nickName: i.displayName
          })
        })
        return deets.concat(u);
      })()
    };
    users.forEach((el) => {
      data[el.id] = true;
    });
    const dRef = this.afs.collection('chats');
    if (id) {
      const userRef = dRef.doc(id).collection('messages').doc(`${id}`).get().subscribe(
        doc => {
          if (doc.exists) {
            // console.log('exists');
            this.router.navigate(['/home/messages'], { queryParams: { id: `${id}` } });
          } else {
            setTimeout(async () => {
              const docRef = await this.afs.collection('chats').add(data);
              this.router.navigate(['/home/messages'], { queryParams: { id: docRef.id } });
            }, 400);
          }
        }
      );
    }
  }

  startChat(chatId): void {
    this.router.navigate(['/home/messages'], { queryParams: { id: chatId } });
  }

  updateUserDetailsOnChat(id, userDetails): void {
    this.afs.collection('chats').doc(id).update({
      userDetails
    });
  }

  updateUserNickname(id, data, nickname, oldNickname){
    try {
      let dat = Object.keys(data).map(key => ({
        email: data['email'],
        nickName: oldNickname,
        id: data['id']
      }));
      let datn = Object.keys(data).map(key => ({
        email: data['email'],
        nickName: nickname,
        id: data['id']
      }));
      this.afs.collection('chats').doc(id).update({
        userDetails: firebase.firestore.FieldValue.arrayRemove(dat[0])
      }).then(() => {
        this.afs.collection('chats').doc(id).update({
          userDetails: firebase.firestore.FieldValue.arrayUnion(datn[0]) 
        })
      });
    } catch (error) {
      console.log(error)
    }
   
  }

  updateEmail(id, data, email){
    const dat = Object.keys(data).map(key => ({
      email: data['email'],
      nickName: data['nickName'],
      id: data['id']
    }));
    const datn = Object.keys(data).map(key => ({
      email: email,
      nickName: data['nickName'],
      id: data['id']
    }));
    this.afs.collection('chats').doc(id).update({
      userDetails: firebase.firestore.FieldValue.arrayRemove(dat[0]) 
    }).then(() => {
      this.afs.collection('chats').doc(id).update({
        userDetails: firebase.firestore.FieldValue.arrayUnion(datn[0]) 
      })
    });
  }

  addUser(id, uids, deets, result) {
    let userDetails = [];
    let memberArray = [];
    for(let i = 0; i < result.length; i++){
      userDetails = userDetails.concat(
        {
          email: result[i].email,
          id: result[i].id,
          nickName: result[i].displayName,
        }
      );
    }
    for(let i = 0; i < result.length; i++){
      memberArray.push(result[i].id)
    }
   
    const obj = {};
    uids.forEach((el) => {
      obj[el] = true;
    });
    const obj2 = Object.assign({}, {userDetails} );
    console.log(userDetails)

    for(let i = 0; i < result.length; i++){
      this.afs.collection('chats').doc(id).update({
        userDetails:firebase.firestore.FieldValue.arrayUnion(userDetails[i]) 
      });
    }
    for(let i = 0; i < result.length; i++){
      this.afs.collection('chats').doc(id).update({
        members:firebase.firestore.FieldValue.arrayUnion(memberArray[i]) 
      });
    }
    return this.afs.collection('chats').doc(id).update(obj);
  }

  removeUser(id, uid, userDetails, displayName?: string): Promise<any> {
    const dRef = this.afs.collection('chats').doc(id);
    console.log(userDetails.uid);
    return dRef.update({
      [uid]: firestore.FieldValue.delete(),
      userDetails: firebase.firestore.FieldValue.arrayRemove(userDetails),
      members: firebase.firestore.FieldValue.arrayRemove(userDetails.id),
      history: firebase.firestore.FieldValue.arrayRemove(userDetails.id)
    });
  }

  async sendMessage(uid, displayName, chatId, photoURL, content, uz,history, file?: URL) {
    let iterator = history.values()
    let historyArray = [];
    
    for(let i = 0; i < history.length; i++){
      //console.log(iterator.next().value);
      historyArray.push(iterator.next().value)
        }

    
    //let { uid, displayName } = user;
    let data;
    if (typeof file !== typeof undefined) {
      data = {
        uid,
        displayName: uz.nickName,
        photoUrl: photoURL,
        downloadUrl: file.downloadUrl || '',
        fileName: file.fileName || '',
        fileType: file.fileType || '',
        content: '',
        createdAt: file.uploaded,
        path: file.path
      };
      if (uid) {
        for(let i = 0; i < history.length; i++){
          this.afs.collection('chats').doc(chatId).update({
            members:firebase.firestore.FieldValue.arrayUnion(history[i]) 
          });
        }

        const ref = this.afs.collection('chats').doc(chatId);
        return ref.update({
          status: 'Active',
          lastModified: Date.now(),
          messages: firestore.FieldValue.arrayUnion(data),
          files: firestore.FieldValue.arrayUnion(file)
        });
      }
    } else {
      data = {
        uid,
        displayName: uz.nickName,
        photoURL,
        content,
        createdAt: Date.now()
      };
      if (uid) {
        for(let i = 0; i < history.length; i++){
          this.afs.collection('chats').doc(chatId).update({
            members:firebase.firestore.FieldValue.arrayUnion(history[i]) 
          });
        }
        
        const ref = this.afs.collection('chats').doc(chatId);
        return ref.update({
          status: 'Active',
          lastModified: Date.now(),
          messages: firestore.FieldValue.arrayUnion(data)
        });
      }
    }
  }
  hideChat(chatId,userId) {
    return this.afs.collection(`chats`).doc(chatId).update({
      lastModified: Date.now(),
      status: 'Hidden',
      members: firebase.firestore.FieldValue.arrayRemove(
        userId
      )})
  }

  deleteChat(chatId) {
    return this.afs.collection(`chats`).doc(chatId).delete()
  }

  deleteMessage(key, data,myId:any){
    const date1 = Math.round(new Date().getTime());

    const docRef = this.afs.collection(`chats`).doc(key)

    const dat = Object.keys(data).map(key => ({
      content: data['content'],
      createdAt: data['createdAt'],
      displayName: data['displayName'],
      photoURL: data['photoURL'],
      uid: data['uid'],
    }));
    
    /*
    const dat2 = Object.keys(data).map(key => ({
      content: data['content'],
      createdAt: data['createdAt'],
      displayName: data['displayName'],
      photoURL: '',
      uid: data['uid'],
    }));
    */
    return docRef.update({
      messages: firebase.firestore.FieldValue.arrayRemove(dat[0])
    })
  }

  deleteFile(key, data){
    const docRef = this.afs.collection(`chats`).doc(key)

    const dat = Object.keys(data).map(key => ({
      content: data['content'],
      createdAt: data['createdAt'],
      displayName: data['displayName'],
      downloadUrl: data['downloadUrl'],
      fileName: data['fileName'],
      fileType: data['fileType'],
      photoUrl: data['photoUrl'],
      path: data['path'],
      uid: data['uid'],
    }));

    const datn = Object.keys(data).map(key => ({
      content: "A file has been deleted",
      createdAt: data['createdAt'],
      displayName: data['displayName'],
      downloadUrl: data['downloadUrl'],
      fileName: data['fileName'],
      fileType: data['fileType'],
      photoUrl: data['photoUrl'],
      path: data['path'],
      uid: data['uid'],
    }));

    const dat2 = Object.keys(data).map(key => ({
      downloadUrl: data['downloadUrl'],
      fileName: data['fileName'],
      fileType: data['fileType'],
      path: data['path'],
      uploaded: data['createdAt'],
    }));
    return docRef.update({
      messages: firebase.firestore.FieldValue.arrayRemove(dat[0]),
      files: firebase.firestore.FieldValue.arrayRemove(dat2[0])
    }).then(() => {
      docRef.update({
        messages: firebase.firestore.FieldValue.arrayRemove(datn[0])
      })
    })
    

  }

  joinUsers(chat$: Observable<any>) {
    let chat;
    const joinKeys = {};
    return chat$.pipe(
      switchMap(c => {
        // Unique User IDs
        chat = c;
        const uids = Array.from(new Set(c.messages.map(v => v.uid)));
        // Firestore User Doc Reads
        const userDocs = uids.map(u =>
          this.afs.doc(`users/${u}`).valueChanges()
        );

        return userDocs.length ? combineLatest(userDocs) : of([]);
      }),
      map(arr => {
        arr.forEach(v => (joinKeys[(<any>v).uid] = v));
        chat.messages = chat.messages.map(v => {
          return { ...v, user: joinKeys[v.uid] };
        });
        if (chat.files && chat.files.length) {
          this.files$.next(chat.files);
        } else {
          this.files$.next([]);
        }
        const a = arr.map((e: any) => ({
          displayName: e.displayName,
          email: e.email,
          uid: e.uid,
        }));
        if (a.length) {
          const chatDetails = {
            id: chat.id,
            creator: chat.creator,
            userDetails: a
          };
          this.users$.next(chatDetails);
        } else {
          const chatDetails = {
            id: chat.id,
            creator: chat.creator,
            userDetails: chat.userDetails
          };
          this.users$.next(chatDetails);
        }
       // console.log({chat,a})
        return { chat, a };
      })
    );
  }
}
