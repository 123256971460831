import {Injectable } from '@angular/core';
import {CanActivate, RouterStateSnapshot, Router, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { take, map, tap, switchMap } from 'rxjs/operators';
import { AuthService, User } from './auth/auth.service';
import { LoginComponent, loginAction } from '../components/layout-components/login/login.component';
import { MatDialog } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(public auth: AuthService, private router: Router, private dialog: MatDialog) {}
  /** Returns true whenever the user is authenticated */
  get authenticated() { return this.auth.authenticated; }

  /** Returns the current authenticated user id */
  get userId() { return this.auth.userId; }

  /** Prompts the user for authentication */
  public prompt(data: loginAction = 'signIn', user?): Promise<User> {
    setTimeout(() => {
      this.dialog.open<LoginComponent, loginAction, User>(LoginComponent, { data }).close();
    }, 1000)
    return this.dialog.open<LoginComponent, loginAction, User>(LoginComponent, { data })
      .afterClosed().toPromise();
  }

  /** Performs the user authentication prompting the user when neeed or resolving to the current authenticated user otherwise */
  public authenticate(action: loginAction = 'signIn'): Promise<User> {

    return this.auth.user$.pipe(
      take(1),
      switchMap( user => !user ? this.prompt(action) : of(user) )
    ).toPromise();
  }

  /** Disconnects the user */
  public disconnect(): Promise<void> {
    return this.auth.signOut();
  }

  // Implements single route user authentication guarding
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // Gets the authorization mode when specified
    const mode = route.queryParamMap.get('authMode') || 'signIn';
    // Prompts the user for authentication
    return this.authenticate(mode as loginAction)
      .then( user => !!user );
  }
 
}
