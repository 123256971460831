import { AuthService } from 'src/app/services/auth/auth.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatSnackBar } from '@angular/material';
import { rotateIn, slideInRight } from 'src/app/animations';
import { Observable, Subscription } from 'rxjs';
import { AuthGuard } from 'src/app/services/auth.guard';
import { UserProfile } from 'src/app/utils/user-profile.service';
import { map } from 'rxjs/operators';
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  animations: [rotateIn, slideInRight]
})
export class SettingsComponent {
  currentUser;
  profileSub: Subscription;
  user$;
  constructor(readonly auth: AuthGuard, private profile: UserProfile, private afAuth: AngularFireAuth) { }

  ngOnInit(): void {
    this.profileSub = this.profile.stream().subscribe(r => {
      this.currentUser = r;
    });
    this.user$ = this.afAuth.auth.currentUser;
  }
}
