import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-editor-controls',
  templateUrl: './editor-controls.component.html',
  styleUrls: ['./editor-controls.component.scss']
})
export class EditorControlsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
