import { Component, OnInit, ViewChild, ComponentFactoryResolver } from '@angular/core';
import { AssignmentControlComponent } from './lead-admin/assignment.component'
import { ContainerDirective } from '../../../../directives/container.directive';
import { AuthGuard } from '../../../../services/auth.guard';
import { UserProfile } from 'src/app/utils/user-profile.service';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { EditorControlComponent } from './editor/assignment.component';

@Component({
  selector: 'app-controls',
  templateUrl: './assignment.component.html',
  styleUrls: ['./assignment.component.scss']
})
export class AssignmentComponent implements OnInit {
  @ViewChild(ContainerDirective, {static: false}) lPage: ContainerDirective;
  dynamicComponent: any;
  readonly role$: Observable<string>;
  containerSubscription: Subscription;
  constructor(private profile: UserProfile, private componentFactoryResolver: ComponentFactoryResolver) {
    this.role$ = this.profile.stream().pipe(map(profile => !!profile ? profile.role : ''));
    console.log(this.profile)
  }
  ngOnInit() {
    this.containerSubscription = this.role$.subscribe(
      r => {
        const frag =  r;
        this.assignComponent(frag);
      }
    );
  }

  assignComponent(value) {
    switch (value) {
      case 'Admin':
        this.dynamicComponent = AssignmentControlComponent;
        break;
      case 'Lead Editor':
        this.dynamicComponent = AssignmentControlComponent;
        break;
      case 'Editor':
        this.dynamicComponent = EditorControlComponent;
      default:
        break;
    }
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.dynamicComponent);
    const viewContainerRef = this.lPage.viewContainerRef;
    viewContainerRef.clear();
    const componentRef = viewContainerRef.createComponent(componentFactory);
  }

}
