import { MatRippleModule } from '@angular/material/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { LandingPageComponent } from './landing-page.component';
import { MatListModule } from '@angular/material';

// Define navigation routes
const routes: Routes = [
  { path: 'login', component: LandingPageComponent },
];

@NgModule({
  imports: [
    MatRippleModule,
    CommonModule,
    FlexLayoutModule,
    MatIconModule,
    MatButtonModule,
    MatListModule,
    RouterModule.forChild(routes),
  ],
  declarations: [
    LandingPageComponent
  ],
  exports: [RouterModule]
})
export class LandingPageModule { }
