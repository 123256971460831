import { EditorControlsComponent } from './controls/editor-controls/editor-controls.component';
import { LeadControlsComponent } from './controls/lead-controls/lead-controls.component';
import { ControlsComponent } from './controls/controls.component';
import { CustomMaterialModule } from './../../../custom-material/custom-material.module';
import { ImageFallbackDirective } from './../../../directives/image-fallback.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { SettingsComponent } from './settings.component';
import { ProfileComponent } from './profile/profile.component';
import { AccountComponent } from './account/account.component';
import { AuthGuard } from 'src/app/services/auth.guard';
import { MatTabsModule, MatDialogModule } from '@angular/material';
import { SharedModule } from 'src/app/directives/shared.module';
import { ContainerDirective } from 'src/app/directives/container.directive';
import { AdminControlsComponent, ReplyDialogComponent } from './controls/admin-controls/admin-controls.component';
import { MatTableModule } from '@angular/material/table';
import { AssignmentComponent } from './assignment/assignment.component';
import { AlertModule } from '../../_alert';
import { UploadAssignmentComponent } from './assignment/upload-assignment/upload-assignment.component';
import { AssignmentControlComponent } from './assignment/lead-admin/assignment.component';
import { EditorControlComponent } from './assignment/editor/assignment.component';
import { PerfectScrollbarModule, PerfectScrollbarConfigInterface, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { SupportComponent, ReplyDialogComponent2 } from './support/support.component';
import { ProjectTrackerComponent } from './project-tracker/project-tracker.component';
import { MyProjectsComponent } from './my-projects/my-projects.component';
import { DialogElementsExampleDialog } from './my-projects/my-projects.component';
import {MatExpansionModule} from '@angular/material/expansion';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true,
  suppressScrollX: true
};

const routes: Routes = [
  { path: 'profile', redirectTo: 'settings/profile', pathMatch: 'full' },
  { path: 'account', redirectTo: 'settings/account', pathMatch: 'full' },
  { path: 'support', redirectTo: 'settings/support', pathMatch: 'full' },
  /*
  { path: 'support', redirectTo: 'settings/myProjects', pathMatch: 'full' },*/
  { path: 'projectTracker', redirectTo: 'settings/projectTracker', pathMatch: 'full' },
  { path: 'controls', redirectTo: 'settings/controls', pathMatch: 'full' },
  /*
  { path: 'assignment', redirectTo: 'settings/assignment', pathMatch: 'full' },*/
  {
    path: 'settings', component: SettingsComponent, canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'profile', pathMatch: 'full' },
      { path: 'profile', component: ProfileComponent },
      { path: 'account', component: AccountComponent },
      { path: 'support', component: SupportComponent },
     // { path: 'myProjects', component: MyProjectsComponent },
      { path: 'controls', component: ControlsComponent },
     // { path: 'projectTracker', component: ProjectTrackerComponent },
      { path: 'assignment', component: AssignmentComponent }
    ]
  }
];

@NgModule({
  imports: [
    AlertModule,
    CommonModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MaterialFileInputModule,
    FormsModule,
    CustomMaterialModule,
    SharedModule,
    MatTableModule,
    MatProgressBarModule,
    MatIconModule,
    MatDialogModule,
    RouterModule.forChild(routes),
    PerfectScrollbarModule,
    MatExpansionModule
  ],
  entryComponents: [
    AdminControlsComponent,
    LeadControlsComponent,
    EditorControlsComponent,
    AssignmentComponent,
    AssignmentControlComponent,
    EditorControlComponent,
    ReplyDialogComponent,
    ReplyDialogComponent2,
    SupportComponent,
    DialogElementsExampleDialog
  ],
  declarations: [
    SettingsComponent,
    ProfileComponent,
    ControlsComponent,
    AssignmentComponent,
    ContainerDirective,
    AccountComponent,
    UploadAssignmentComponent,
    AdminControlsComponent,
    LeadControlsComponent,
    EditorControlsComponent,
    AssignmentControlComponent,
    EditorControlComponent,
    ReplyDialogComponent,
    ReplyDialogComponent2,
    SupportComponent,
    ProjectTrackerComponent,
    MyProjectsComponent,
    DialogElementsExampleDialog
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ],
  exports: [RouterModule]
})
export class SettingsModule { }
