import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import { FormGroup, FormBuilder, Validators, FormControl, NgForm } from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UserProfile } from 'src/app/utils/user-profile.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { AngularFireUploadTask, AngularFireStorage } from '@angular/fire/storage';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { UploadTask } from 'src/app/services/storage/upload-task';
import { PerfectScrollbarConfigInterface, PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { ChatService } from 'src/app/services/chat.service';
import { GetUsersService } from 'src/app/services/get-users.service';
import { AlertService } from 'src/app/components/_alert'
import { AngularFireAuth } from '@angular/fire/auth';
import { FormsModule } from '@angular/forms'
import './../../../../../services/mail/smtp.js'
import { AssignmentService } from 'src/app/services/assignment/assignment.service';
import * as moment from 'moment';
import { AngularFirestore } from '@angular/fire/firestore';
declare let Email: any;

@Component({
  selector: 'app-editor-assignment',
  templateUrl: './assignment.component.html',
  styleUrls: ['./assignment.component.scss']
})
export class EditorControlComponent implements OnInit{

  @Input() users: Observable<any>; 
  assignments: Observable<any>;
  @Output() close = new EventEmitter<boolean>();
  // phone1 = PhoneHold;
  // phone2 = Phone;
  // jPhone = JPhone;
  currentUser;
  more;
  currentUSerSubscription: Subscription;
  groupForm = this.fb.group({
    'name': new FormControl('', Validators.required)
  });
  public type = 'component';
  public config: PerfectScrollbarConfigInterface = {};
  @ViewChild(PerfectScrollbarComponent, {static: false}) componentRef?: PerfectScrollbarComponent;
  showList: boolean; 
  startAt: BehaviorSubject<string | null> = new BehaviorSubject('');
  group = [] as any;
  get auth() { return this.profile.auth; }
  user$;
  constructor(
    private profile: UserProfile,
    private as: AssignmentService,
    private afs: AngularFirestore,
    private cs: ChatService,
    private fb: FormBuilder,
    private getUsersService: GetUsersService,
    private afAuth: AngularFireAuth,
    protected alertService: AlertService) { }

  ngOnInit() {
    this.currentUSerSubscription = this.profile
      .stream().subscribe(
        r => {
          this.currentUser = r; 
          this.assignments = this.as.getMyAssignments(this.currentUser.email);
        }
      );
    this.users = this.getUsersService.getUsers(this.startAt);
    this.user$ = this.afAuth.auth.currentUser;
   
  }
  get name() { return this.groupForm.get('name'); }

  search($event): void {
    const q = $event.target.value;
    this.startAt.next(q);
    // this.endAt.next(q + '\uf8ff');
  }
  formatDate(date): string {
    return moment.unix(date.seconds).format("ddd MMM D YYYY");
  }

  seeMore(option){
    this.more = option;
  }

  acceptAssignment(admin, _id){
    Email.send({
      Host : 'smtp.gmail.com',
      Username : 'gfc.firebase@gmail.com',
      Password : 'yvmtwwbkzikmskdi',
      To : admin,
      From : this.user$.email,
      Subject : 'Assignment Accepted',
      Body : `Editor ${this.user$.displayName} has accepted work assigned.`
      }).then(
        message => {
  
          if(message === 'OK')
              alert( 'Successfully Sent!');
          else
            alert('Error: '+ message);
        }
      );
    return this.afs.doc(`assignments/${_id}`).update({status: "On Going",projectStatus: "On Going"});
  }

  rejectAssignment(admin, _id: string){
    Email.send({
      Host : 'smtp.gmail.com',
      Username : 'gfc.firebase@gmail.com',
      Password : 'yvmtwwbkzikmskdi',
      To : admin,
      From : this.user$.email,
      Subject : 'Assignment Accepted',
      Body : `Editor ${this.user$.displayName} has rejected work assigned.`
      }).then(
        message => {
  
          if(message === 'OK')
              alert( 'Successfully Sent!');
          else
            alert('Error: '+ message);
        }
      );
    return this.afs.doc(`assignments/${_id}`).update({status: "Rejected"});
  }


    
}
