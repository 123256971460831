import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { AngularFireStorage } from '@angular/fire/storage';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { GetUsersService } from 'src/app/services/get-users.service';
// import './../../../../../services/mail/smtp.js'
import '../../../services/mail/smtp.js'
declare let Email: any;
import { AssignmentService } from 'src/app/services/assignment/assignment.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-project',
  templateUrl: './add-project.component.html',
  styleUrls: ['./add-project.component.scss']
})
export class AddProjectComponent implements OnInit {
  taskTitle = new FormControl('', Validators.required);
  taskClient: any;
  taskEditor: any;
  taskWorktype = new FormControl('', Validators.required);
  taskDuration = new FormControl('', Validators.required);
  taskDescription = new FormControl('', Validators.required);
  date = new FormControl('', Validators.required);
  @Input() users: Observable<any>;
  @Input() editors: Observable<any>;
  startAt: BehaviorSubject<string | null> = new BehaviorSubject('');

  path: any;
  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;
  projectTimeline: any;
  projectStatus: any;
  dueDate: any;
  constructor(
    private af: AngularFireStorage,
    private getUsersService: GetUsersService,
    private as: AssignmentService,
    readonly router: Router,
  ) { }

  ngOnInit() {
    this.users = this.getUsersService.getUsers(this.startAt);
    this.editors = this.getUsersService.getEditors(this.startAt);
    console.log(this.editors)
  }

  upload($event) {
    this.path = $event.target.files[0]
  }

  search($event): void {
    const q = $event.target.value;
    this.startAt.next(q);
    // this.endAt.next(q + '\uf8ff');
  }

  uploadImage() {
    const filePath = "/GFC-Assignments/" + this.path.name;
    const fileRef = this.af.ref(filePath);
    const task = this.af.upload(filePath, this.path);
    this.uploadPercent = task.percentageChanges();
    // get notified when the download URL is available
    task.snapshotChanges().pipe(
      // finalize(() => this.downloadURL = fileRef.getDownloadURL())
      finalize(() => {
        this.downloadURL = fileRef.getDownloadURL();
        this.downloadURL.subscribe(url => {
          // console.log(url)

        })
      })
    )
      .subscribe()
  }

  public displayProperty(value) {
    if (value) {
      return value.email;
    }
  }
  //taskName, taskEditor, taskClient, workType, duration, dueDate, projectTimeline ,fileName, fileUrl, status

  submitAssignment() {

    this.projectStatus = 'Pending'
    Email.send({
      Host: 'smtp.gmail.com',
      Username: 'gfc.firebase@gmail.com',
      Password: 'yvmtwwbkzikmskdi',
      To: this.taskEditor.email,
      From: 'admin@clientmentorportal.com',
      Subject: 'New assignment alert',
      Body: `You are being sent details about a new assignment<br/><br/>
          <b>Client Email: </b>${this.taskClient.email}<br />
          <b>Work type: </b>${this.taskWorktype.value}<br />
          <b>Duration: </b>${this.taskDuration.value}<br />
          <b>Due date: </b>${this.date.value}<br />
          <b>Comments:</b> <br />
         
          Please login to your account at <a>https://clientmentorportal.com/</a> to accept or reject this assignment.<br/><br/>
          <b>~End of Message.~</b>`
    })
    const filePath = "/GFC-Assignments/" + this.path.name;
    const fileRef = this.af.ref(filePath);
    const task = this.af.upload(filePath, this.path);
    this.uploadPercent = task.percentageChanges();
    // get notified when the download URL is available
    task.snapshotChanges().pipe(
      finalize(() => {
        this.downloadURL = fileRef.getDownloadURL();
        this.downloadURL.subscribe(url => {
          this.as.uploadAssignment(this.taskTitle.value,this.taskEditor.email,this.taskClient.email,this.taskWorktype.value,this.date.value,this.path.name,url,this.projectStatus,this.taskDescription.value,this.taskEditor.id)
          .then(() => {
            alert('Assignment Uploaded!')
          })
          .then(() => {
            this.router.navigate(['/home/project-management']);
          })
          .catch(() => {
            alert('There has been a problem with the upload. Pls try again.')
          })
        })
      })
    )
      .subscribe()

  }

  showDate(e) {
      console.log(this.date.value);
  }
}

    