import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import { FormGroup, FormBuilder, Validators, FormControl, NgForm } from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UserProfile } from 'src/app/utils/user-profile.service';

import { AngularFireUploadTask, AngularFireStorage } from '@angular/fire/storage';

import { PerfectScrollbarConfigInterface, PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { ChatService } from 'src/app/services/chat.service';
import { GetUsersService } from 'src/app/services/get-users.service';
import { AlertService } from 'src/app/components/_alert'
import { AngularFireAuth } from '@angular/fire/auth';
import { FormsModule } from '@angular/forms'
import { Model } from './assignment';
import './../../../../../services/mail/smtp.js'
import { AssignmentService } from 'src/app/services/assignment/assignment.service';
declare let Email: any;

@Component({
  selector: 'app-assignment',
  templateUrl: './assignment.component.html',
  styleUrls: ['./assignment.component.scss']
})
export class AssignmentControlComponent implements OnInit{
  model: Model = new Model();
  files: File[] = [];
  event;
 
  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadURL: string;

  @Input() users: Observable<any>;
  @Output() chatId: string;
  @Output() close = new EventEmitter<boolean>();
  // phone1 = PhoneHold;
  // phone2 = Phone;
  // jPhone = JPhone;
  currentUser;
  currentUSerSubscription: Subscription;
  groupForm = this.fb.group({
    'name': new FormControl('', Validators.required)
  });
  public type = 'component';
  public config: PerfectScrollbarConfigInterface = {};
  @ViewChild(PerfectScrollbarComponent, {static: false}) componentRef?: PerfectScrollbarComponent;
  showList: boolean;
  startAt: BehaviorSubject<string | null> = new BehaviorSubject('');
  group = [] as any;
  get auth() { return this.profile.auth; }
  user$;
  projectStatus;
  projectTimeline;
  assgn$: Observable<any>;
  constructor(
    private profile: UserProfile,
    private as: AssignmentService,
    private cs: ChatService,
    private fb: FormBuilder,
    private getUsersService: GetUsersService,
    private afAuth: AngularFireAuth,
    protected alertService: AlertService,
    private storage: AngularFireStorage) { }

  ngOnInit() {
    this.currentUSerSubscription = this.profile
      .stream().subscribe(
        r => {
          this.currentUser = r;
        }
      );
    this.users = this.getUsersService.getUsers(this.startAt);
    this.user$ = this.afAuth.auth.currentUser;
    this.as.getAllAssignments().subscribe((assgn$)=> {
      this.assgn$ = assgn$//,   console.log(assgn$);
    });
 
  }
  get name() { return this.groupForm.get('name'); }

  search($event): void {
    const q = $event.target.value;
    this.startAt.next(q);
    // this.endAt.next(q + '\uf8ff');
  }
  onDrop(files: FileList): void {
      for (let i = 0; i < files.length; i++) {
        this.files.push(files.item(i));
      }
      console.log(files);
  }
  calculatePercentage(event): void {
    this.percentage = event;
  }

  addFile(event): void {
      let uz;
      //this.cs.sendMessage(this.user$, this.chatId, '', '', uz, event);
      console.log(event)
    this.files = []
    this.event= event;
    console.log(Object.values(event))
    window.alert('File has been uploaded! Please submit once all fields have been filled up')
    
  }
 

  addUser(u: any): void {
    if (this.group.some(user => user.uid === u.uid)) {
      return;
    } else {
      this.group.push(u);
    }
  }

  

  removeFromGroup(uid): void {
    this.group = this.group.filter(user => user.uid !== uid);
  }
  test() {
    
  }

  onSubmit(f: NgForm) {
    var dateToday1 = new Date(Date.now());
      var numberOfDaysToAdd = 45;
      dateToday1.setDate(dateToday1.getDate() + numberOfDaysToAdd); 
      console.log(dateToday1)
      this.projectTimeline = dateToday1.toDateString();
      this.projectStatus = 'Sent'
    if (this.model.duration == "Urgent") {
      var dateToday = new Date(Date.now());
      console.log(dateToday)
      var numberOfDaysToAdd = 2;
      dateToday.setDate(dateToday.getDate() + numberOfDaysToAdd); 
      console.log(dateToday)
      this.model.dueDate = dateToday.toDateString()
    }
    if (this.model.duration == "Not Urgent") {
      var taskType = this.model.workType;
      var lastFive = taskType.substr(taskType.length - 5); //write or edit
      if (lastFive == 'Write') {
        var dateToday = new Date(Date.now());
        console.log(dateToday)
        var numberOfDaysToAdd = 5;
        dateToday.setDate(dateToday.getDate() + numberOfDaysToAdd); 
        console.log(dateToday)
        this.model.dueDate = dateToday.toDateString()
      }
      else if (lastFive == ' Edit') {
        var dateToday = new Date(Date.now());
        console.log(dateToday)
        var numberOfDaysToAdd = 3;
        dateToday.setDate(dateToday.getDate() + numberOfDaysToAdd); 
        console.log(dateToday)
        this.model.dueDate = dateToday.toDateString()
      } else {
        var dateToday = new Date(Date.now());
        console.log(dateToday)
        var numberOfDaysToAdd = 5;
        dateToday.setDate(dateToday.getDate() + numberOfDaysToAdd); 
        console.log(dateToday)
        this.model.dueDate = dateToday.toDateString()
      }
    }
    Email.send({
    Host : 'smtp.gmail.com',
    Username : 'gfc.firebase@gmail.com',
    Password : 'yvmtwwbkzikmskdi',
    To : this.model.editor,
    From : 'admin@clientmentorportal.com',
    Subject : 'New assignment alert',
    Body : `You are being sent details about a new assignment<br/><br/>
        <b>Client Name: </b>${this.model.clientFirst} ${this.model.clientLast}<br />
        <b>Client Email: </b>${this.model.clientEmail}<br />
        <b>Work type: </b>${this.model.workType}<br />
        <b>Duration: </b>${this.model.duration}<br />
        <b>Due date: </b>${this.model.dueDate}<br />
        <b>Comments:</b> <br />
        ${this.model.message? this.model.message: "No additional comments"} <br><br>
    
        Please login to your account at <a>https://clientmentorportal.com/</a> to accept or reject this assignment.<br/><br/>
        <b>~End of Message.~</b>`
    }).then(
      message => {

        if(message === 'OK')
            alert( 'Successfully Sent!');
        else
          alert('Error: '+ message);

          f.resetForm(); }, err => {alert(err); }
    );
    // this.as.sendAssignment(this.model.taskName,this.model.clientFirst, this.model.clientLast, this.model.clientEmail, this.model.workType, this.model.duration, this.model.dueDate, this.model.editor, this.user$.email, this.model.message? this.model.message : "No additional comments", 'Sent',this.event,this.projectTimeline,this.projectStatus)
    }
}
