import { Component, OnInit, OnDestroy } from '@angular/core';
import { routerTransition, slideUpInDownOut } from './animations';
import { AuthGuard } from './services/auth.guard';
import { UserProfile } from './utils/user-profile.service';
import { Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatIconRegistry } from '@angular/material';
import { SidenavService } from './services/sidenav.service';
//import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
//import { Keepalive } from '@ng-idle/keepalive';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routerTransition, slideUpInDownOut]
})
export class AppComponent implements OnInit, OnDestroy {
  readonly userName$: Observable<string>;
  readonly photo$: Observable<string>;
  private sub: Subscription;

  readonly footerItems = [
    { icon: "fab:fa-angular", link: "https://angular.io" },
    { icon: "fab:fa-github", link: "https://github.com" },
    { icon: "fab:fa-medium", link: "https://medium.com/wizdm-genesys" }
  ];

  get auth() { return this.profile.auth; }

  get authenticated() { return this.guard.authenticated; }

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;

  constructor(
    readonly guard: AuthGuard,
    private sidenavService: SidenavService,
    private profile: UserProfile,
    readonly router: Router,
    private icon: MatIconRegistry,
    /*private idle: Idle,
    private keepalive: Keepalive*/) {
    // Streams the user name from the profile
    this.userName$ = this.profile.stream().pipe(
      map(data => !!data ? data.displayName : '')
    );
    // Streams the profile photo
    this.photo$ = this.profile.stream().pipe(map(profile => !!profile ? profile.photoURL : ''));
    /*idle.setIdle(5);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(5);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => { 
      this.idleState = 'No longer idle.'
      console.log(this.idleState);
      this.reset();
    });
    
    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      console.log(this.idleState);
      this.router.navigate(['/']);
    });
    
    idle.onIdleStart.subscribe(() => {
        this.idleState = 'You\'ve gone idle!'
        console.log(this.idleState);
        
    });
    
    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!'
      console.log(this.idleState);
    });

    // sets the ping interval to 15 seconds
    keepalive.interval(15);

    keepalive.onPing.subscribe(() => this.lastPing = new Date());

    this.reset();*/

  }

  ngOnInit() {
    // Registers font awesome among the available sets of icons for mat-icon component
    this.icon.registerFontClassAlias('fontawesome', 'fa');
    // Monitors the authState making sure to navigate home whenever the user signs out
    // including when the account has been deleted
    this.sub = this.auth.authState$.pipe(filter(user => !user)).subscribe(() => this.router.navigate(['/']));
    
  }

  ngOnDestroy() { this.sub.unsubscribe(); }

  ngAfterContentInit(): void {
  }
  toggleNav(): void {
    this.sidenavService.toggle();
  }

  /*reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }*/

  logout(){
    this.auth.signOut();
    this.guard.prompt('signIn');
  }

}
