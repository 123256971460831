import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject, Observable,Subscription } from 'rxjs';
import { AssignmentService } from 'src/app/services/assignment/assignment.service.js';
import { AuthService } from 'src/app/services/auth/auth.service';
import { GetUsersService } from 'src/app/services/get-users.service';
import { UserProfile } from 'src/app/utils/user-profile.service';

@Component({
  selector: 'app-user-inquiries',
  templateUrl: './user-inquiries.component.html',
  styleUrls: ['./user-inquiries.component.scss']
})
export class UserInquiriesComponent implements OnInit {
  showFiller = true;
  items$;
  flaggedItems$;
  resolvedItems$;
  currentUSerSubscription: Subscription;
  currentUser;
  user$;
  startAt: BehaviorSubject<string | null> = new BehaviorSubject('');
  private get auth(): AuthService { return this.profile.auth; }

  constructor(
    private as: AssignmentService,
    private profile: UserProfile,
    private afs: AngularFirestore,
    private afAuth: AngularFireAuth,
    private getUsersService: GetUsersService,
  ) { }

  ngOnInit() {
    this.currentUSerSubscription = this.profile
    .stream().subscribe(
      r => {
        this.currentUser = r; console.log(this.currentUser)
        this.items$ = this.getUsersService.getMessages(this.startAt);
        this.flaggedItems$ = this.getUsersService.getFlaggedMessages(this.startAt);
        this.resolvedItems$ = this.getUsersService.getResolvedMessages(this.startAt);
      }
    );
    // this.user$ = this.afAuth.auth.currentUser;
  }

  flagMessage(id,flagged) {
      this.getUsersService.flagMessage(id,!flagged);
  }

}
