import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatSidenav } from '@angular/material';
import { Subscription } from 'rxjs';
import { SidenavService } from 'src/app/services/sidenav.service';
import { UserProfile } from 'src/app/utils/user-profile.service';
import {MatDialog} from '@angular/material/dialog';
import {FormControl, Validators} from '@angular/forms';
import { AssignmentService } from 'src/app/services/assignment/assignment.service';

export interface NavMenu {
  icon: string;
  name: string;
  link: string;
}

@Component({
  selector: 'app-client-navigation',
  templateUrl: './client-navigation.component.html',
  styleUrls: ['./client-navigation.component.scss']
})
export class ClientNavigationComponent implements OnInit {
  
  navMenu: NavMenu[] = [
    {
      icon: 'message',
      name: 'Chat',
      link: '/home/messages'
    },
    {
      icon: 'date_range',
      name: 'Scheduling',
      link: '/home/scheduling'
    }
  ];
  profileSub: Subscription;
  currentUser;
  user$;

  constructor(private sidenavService: SidenavService,
    private profile: UserProfile,
    private afAuth: AngularFireAuth,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.profileSub = this.profile.stream().subscribe(r => {
      this.currentUser = r;
    });
    this.user$ = this.afAuth.auth.currentUser;
  } 

  toggleNav(): void {
    this.sidenavService.toggle(); 
  }

  openDialog() {
    this.dialog.open(DialogElementsExampleDialog);
  }

}

@Component({
  selector: 'dialog-elements-example-dialog',
  templateUrl: 'dialog-elements-example-dialog.html',
  styleUrls: ['./client-navigation.component.scss']
})
export class DialogElementsExampleDialog {
  message = new FormControl('', Validators.required);
  subject = new FormControl('', Validators.required);
  user$;
  currentUser;
  currentUSerSubscription: Subscription;
  constructor(
    private as: AssignmentService,
    private afAuth: AngularFireAuth,
    private profile: UserProfile,
    ) {

  }
  ngOnInit() {
    this.user$ = this.afAuth.auth.currentUser;
    this.currentUSerSubscription = this.profile
    .stream().subscribe(
      r => {
        this.currentUser = r;
      }
    );
  }
  sendMessage() {
    if (this.message.value == '') {
      this.message.touched;
    } 
    if (this.subject.value == '') {
      this.subject.touched;
    } 
    else if (this.message.value != "" && this.subject.value != "") {
      this.as.sendMessageAdmin(this.currentUser.displayName, this.user$.email, this.subject.value, this.message.value).then(()=> {
        window.alert('You have successfully sent a support message.');
      })
      .catch(() => {
        window.alert('There was a problem with your request.')
      })
    }
    
  }
}
