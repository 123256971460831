import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AuthGuard } from 'src/app/services/auth.guard';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { BehaviorSubject, Subscription } from 'rxjs';
import { GetUsersService } from 'src/app/services/get-users.service';
import { UserProfile } from 'src/app/utils/user-profile.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { PerfectScrollbarComponent, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AngularFirestore } from '@angular/fire/firestore';
import './../../../../../services/mail/smtp.js'
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { AssignmentService } from 'src/app/services/assignment/assignment.service.js';
import {MatExpansionModule} from '@angular/material/expansion';

declare let Email: any;

export interface ReplyData {
  from?: string;
  content?: string;
  reply?: string;
  subject?: string
  email?:string;
  id?:string;
}

@Component({
  selector: 'app-admin-controls',
  templateUrl: './admin-controls.component.html',
  styleUrls: ['./admin-controls.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class AdminControlsComponent implements OnInit {
  panelOpenState = false;
  currentUSerSubscription: Subscription;
  currentUser; 
  users$;
  messages$;
  startAt: BehaviorSubject<string | null> = new BehaviorSubject('');
  public type = 'component';
  public config: PerfectScrollbarConfigInterface = {};
  @ViewChild(PerfectScrollbarComponent, {static: false}) componentRef?: PerfectScrollbarComponent;
  page: any;
  user$: any;
  see: any;
  constructor(private guard: AuthGuard, public dialog: MatDialog, private getUsersService: GetUsersService, private profile: UserProfile,
    private afAuth: AngularFireAuth, private _snackBar: MatSnackBar, private af: AngularFirestore, private as: AssignmentService) { }

  get auth() { return this.profile.auth; }
  ngOnInit(): void {
    this.currentUSerSubscription = this.profile
      .stream().subscribe(
        r => {
          this.currentUser = r;
        }
      );
    this.users$ = this.getUsersService.getUsersList(this.startAt);
    this.user$ = this.afAuth.auth.currentUser;
    this.messages$ = this.getUsersService.getMessages(this.startAt)
    this.setPage('user');
  }

  // Prompts for account register
  public createAccount() {

    this.guard.prompt('register')
      .then(user => { });
  }
/*
  public deleteAccount(key, email) {
    this.af.collection('chats')
    this.af.collection(`users`, ref => ref.orderBy('displayName')).valueChanges().subscribe(users => {
      users.map((user: any) => {
        this.af.doc(`users/${key}`).delete()
          .catch(error => {console.log(error); })
          .then(() => console.log(`Deleting user (${key})`));
      });
    });
  }
*/
  public deleteAccount(user) {    
    console.log(user)
    if (window.confirm("Do you really want delete " + user.email + "?")) {
      this.auth.adminDeleteUser(user.id)
    }  }

  public setPage(pagetype){
    this.page = pagetype;
  }

  seeMore(type){
    this.see = type;
  }

  deleteMessage(from,msg,id){
    console.log(from,msg,id);
    this.as.deleteMessage(from,msg,id);
  }

  deleteMessageDocument(id) {
    this.as.deleteMessageDocument(id)
  }

  reply(from, subject, email,id,message){
    const dialogRef = this.dialog.open(ReplyDialogComponent, {
      width: '500px',
      data: { from, message: '', subject, email, id }
    });

    dialogRef.afterClosed().subscribe(result => {
        console.log(result)
    });
  }
}

@Component({
  selector: 'app-reply-dialog',
  template: `
  <h1 mat-dialog-title class="dialog-title">Reply to message</h1>
  <div mat-dialog-content>
    <p> Subject: {{data.subject}} </p>
    <p> From: {{data.from}} </p>
    <p> Reply: </p>
    <mat-form-field style="font-size: 13px; width: 100% ;margin-right: 1.4%;" appearance="outline">
      <textarea matInput placeholder="Enter reply here..." style="width:100%; max-width:100%;" [(ngModel)]="data.reply" cdkFocusInitial> </textarea>
    </mat-form-field>
  </div>
  <div mat-dialog-actions align="end" class="m-0">
    <button class= "no-focus" mat-button color="default" (click)="onNoClick()">Cancel</button>
    <button class= "no-focus" mat-raised-button color="primary" (click)="replyAdmin(data?.from, data?.reply,data?.email,data?.subject,data?.id)" cdkFocusInitial>Send</button>
  </div>
  `,
  styles: [`
    .dialog-title {
      font-family: 'Poppins', san-serif;
    }
    .no-focus {
      font-family: 'Poppins', san-serif;
      outline: 0
    }
    .context {
      font-family: 'Poppins', san-serif;
      font-size: 13px;
      font-weight: 400
    }
  `]
})
export class ReplyDialogComponent {
  private reply: FormControl;
  constructor(
    public dialogRef: MatDialogRef<ReplyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ReplyData,private as: AssignmentService) { 
      this.reply = new FormControl(data, [Validators.required]);
      console.log(this.data.id)
    }

  onNoClick(): void {

    this.dialogRef.close();
  }

  replyAdmin(from, subject, message,email,id) {
    from = 'Admin';
    subject = this.data.subject;
    message = this.data.reply;
    email = this.data.email;
    id = this.data.id;
    this.as.replyMessageAdmin(from, message, email,subject,id).then(() => {
      this.dialogRef.close();
    })
  }
}


