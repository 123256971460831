import {
  trigger,
  keyframes,
  state,
  stagger,
  group,
  animate,
  transition,
  style,
  query,
  sequence,
  animateChild,
} from '@angular/animations';

export const slideUpInDownOut = trigger('slideUpInDownOut', [
  transition(':enter', [
    style({ transform: 'translateY(50px)', opacity: 0 }),
    animate('800ms cubic-bezier(0.25, 0.8, 0.25, 1)', style({ transform: 'translateY(0px)', opacity: 1 }))
  ]),
  transition(':leave', [
    animate('400ms cubic-bezier(0.25, 0.8, 0.25, 1)', style({ transform: 'translateY(50px)', opacity: 0 }))
  ])
]);

export const rotateIn = trigger('rotateIn', [
  transition(':enter', [
    style({transform: 'rotate(-90deg)', opacity: 0}),
    animate('1000ms cubic-bezier(.8,-0.48,0,1.06)', style({transform: 'rotate(0deg)', opacity: 1}))
  ]),
  transition(':leave', [
    animate('1000ms cubic-bezier(.8,-0.48,0,1.06)', style({transform: 'rotate(90deg)', opacity: 0}))
  ])
]);


export const slideInOut = trigger('slideInOut', [
  state('in', style({ height: '*', opacity: 0 })),
  transition(':leave', [
    style({ height: '*', opacity: 1 }),

    group([
      animate(300, style({ height: 0 })),
      animate('200ms ease-in-out', style({ 'opacity': '0' }))
    ])

  ]),
  transition(':enter', [
    style({ height: '0', opacity: 0 }),

    group([
      animate(300, style({ height: '*' })),
      animate('400ms ease-in-out', style({ 'opacity': '1' }))
    ])

  ])
]);

export const fadeInSlideUp = trigger('fadeInSlideUp', [
  transition(':enter', [
    style({ transform: 'translateY(150px)', opacity: 0 }),
    animate('400ms 800ms cubic-bezier(0.25, 0.8, 0.25, 1)', style({ transform: 'translateY(0px)', opacity: 1 }))
  ]),
  transition(':leave', [
    animate('400ms 0ms cubic-bezier(0.25, 0.8, 0.25, 1)', style({ transform: 'translateY(90px)', opacity: 0 }))
  ])
]);

export const listStagger = trigger('listStagger', [
  transition('* <=> *', [
    query(
      ':enter',
      [
        style({ opacity: 0, transform: 'translateY(-15px)' }),
        stagger(
          '50ms',
          animate(
            '550ms ease-out',
            style({ opacity: 1, transform: 'translateY(0px)' })
          )
        )
      ],
      { optional: true }
    ),
    query(':leave', animate('50ms', style({ opacity: 0 })), {
      optional: true
    })
  ])
]);


export const slideInRight = trigger('slideInRight', [
  transition(':enter', [
    style({ transform: 'translateX(90px)', opacity: 0, position: 'absolute' }),
    animate('400ms cubic-bezier(0.25, 0.8, 0.25, 1)', style({ transform: 'translateX(0px)', opacity: 1 }))
  ]),
  transition(':leave', [
    animate('400ms 0ms cubic-bezier(0.25, 0.8, 0.25, 1)', style({ transform: 'translateX(90px)', opacity: 0 }))
  ])
]);
export const slideInLeft = trigger('slideInLeft', [
  transition(':enter', [
    style({ transform: 'translateX(-90px)', opacity: 0 }),
    animate('400ms cubic-bezier(0.25, 0.8, 0.25, 1)', style({ transform: 'translateX(0px)', opacity: 1 }))
  ]),
  transition(':leave', [
    animate('400ms 0ms cubic-bezier(0.25, 0.8, 0.25, 1)', style({ transform: 'translateX(-90px)', opacity: 0 }))
  ])
]);

export const routerTransition =
  trigger('routeAnimations', [
    transition('SignIn => App', [
      style({ position: 'relative', width: '100%' }),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          width: '100%'
        })
      ]),
      query(':enter', style({ opacity: 0 })),
      sequence([
        query(':leave', animateChild()),
        group([
          query(':leave', [
            style({ opacity: 1 }),
            animate('.2s ease',
              style({ opacity: 0 }))
          ]),
          query(':enter', [
            style({ opacity: 0 }),
            animate('.2s ease',
              style({ opacity: 1 })),
          ]),
        ]),
      ])
    ]),
    transition('App => SignIn', [
      style({ position: 'relative', width: '100%' }),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          width: '100%'
        })
      ]),
      query(':enter', style({ opacity: 0 })),
      sequence([
        group([
          query(':leave', [
            style({ opacity: 1 }),
            animate('.2s ease',
              style({ opacity: 0 }))
          ]),
          query(':enter', [
            style({ opacity: 0 }),
            animate('.2s ease',
              style({ opacity: 1 })),
          ]),
        ]),
        query(':enter', animateChild()),
      ])
    ])
  ]);
