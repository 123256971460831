import { Component, OnInit, Input } from '@angular/core';
import { Params, ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { AssignmentService } from 'src/app/services/assignment/assignment.service';
import { FormControl, Validators } from '@angular/forms';
import { AngularFireStorage } from '@angular/fire/storage';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { GetUsersService } from 'src/app/services/get-users.service';
// import './../../../../../services/mail/smtp.js'
import '../../../services/mail/smtp.js'
declare let Email: any;
import { Router } from '@angular/router';
@Component({
  selector: 'app-edit-project',
  templateUrl: './edit-project.component.html',
  styleUrls: ['./edit-project.component.scss']
})
export class EditProjectComponent implements OnInit {
  assign;
  taskTitle = new FormControl('', Validators.required);
  taskClient = new FormControl('', Validators.required);
  taskEditor = new FormControl('', Validators.required);
  taskWorktype = new FormControl('', Validators.required);
  date = new FormControl('', Validators.required);
  taskDescription = new FormControl('', Validators.required);
  @Input() users: Observable<any>;
  startAt: BehaviorSubject<string | null> = new BehaviorSubject('');

  path: any;
  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;
  projectTimeline: any;
  projectStatus: any;
  dueDate: any;
  constructor(
    private route: ActivatedRoute,
    private as: AssignmentService,
    private getUsersService: GetUsersService,
    public router: Router,
  ) { }

  ngOnInit() {
    this.route.params.pipe(
      switchMap((params: Params) => {
        return this.as.getAssignment(params['id'])
      })
    ).subscribe((res) => {
        this.assign = res;
        console.log(this.assign)  
        this.taskTitle.setValue(res.taskName)
        this.taskClient.setValue(res.taskClient)
        this.taskEditor.setValue(res.taskEditor)
        this.taskWorktype.setValue(res.workType)
        this.taskDescription.setValue(res.taskDescription)
        this.date.setValue(res.dueDate)

      })

    this.users = this.getUsersService.getUsers(this.startAt);
  }

  upload($event) {
    this.path = $event.target.files[0]
  }

  search($event): void {
    const q = $event.target.value;
    this.startAt.next(q);
    // this.endAt.next(q + '\uf8ff');
  }

  updateProject(id,status) {
    this.as.updateAssignment(id,this.taskTitle.value,this.taskEditor.value,this.taskClient.value,this.taskWorktype.value,this.date.value,status,this.taskDescription.value).then(() => {
      this.router.navigate(['home/project-management/',id])
    })
  }

}
