import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {MatListModule} from '@angular/material/list';


@Component({
  selector: 'app-select-profile',
  templateUrl: './select-profile.component.html',
  styleUrls: ['./style.css'],
  encapsulation: ViewEncapsulation.None
})
export class SelectProfileComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
