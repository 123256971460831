import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';

import { map, switchMap, filter, tap, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { firestore } from 'firebase';
import { Observable, combineLatest, of, Subject, BehaviorSubject } from 'rxjs';
import { FirebaseAuth } from '@angular/fire';
import { AngularFireAuth } from '@angular/fire/auth';
import * as _ from 'lodash';
import { MatSnackBar } from '@angular/material';
import { AuthService } from '../auth/auth.service';
import './../../services/mail/smtp.js'
declare let Email: any;
import {URL} from '../../components/layout-components/settings/assignment/upload-assignment/upload-assignment.component';
import { Messages } from '@progress/kendo-angular-inputs';
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class AssignmentService {
  users$ = new BehaviorSubject<any>({});
  users = this.users$.asObservable();
  currentUser$ = new BehaviorSubject<[]>([]);
  currentUser = this.currentUser$.asObservable();
  files$ = new BehaviorSubject<[]>([]);
  files = this.files$.asObservable();

  constructor(
    private afs: AngularFirestore,
    private auth: AuthService,
    private router: Router,
    private fsAuth: AngularFireAuth,
    private _snackBar: MatSnackBar
  ) { }


  // sendAssignment(taskName,clientFname, clientLname, clientEmail, workType, duration, dueDate, editorAssigned, sender, comments, status,file,projectTimeline,projectStatus) {
  //   dueDate = new Date(dueDate).toLocaleDateString('sv-SE')
  //   projectTimeline = new Date(projectTimeline).toLocaleDateString('sv-SE')
  //   let data;
  //   data = {
  //     taskName,
  //     clientFname, 
  //     clientLname, 
  //     clientEmail, 
  //     workType, 
  //     duration, 
  //     dueDate, 
  //     editorAssigned,
  //     sender, 
  //     comments,
  //     status,
  //     file,
  //     projectStatus,
  //     projectTimeline,
  //     created_at: new Date(Date.now()).toLocaleDateString('sv-SE')
  //   };
  //   const ref = this.afs.collection('assignments');
  //   return ref.add(data)
    
  // }  

  uploadAssignment(taskName, taskEditor, taskClient, workType, dueDate ,fileName, fileUrl, status, taskDescription,editorId) {
    dueDate = new Date(dueDate).toLocaleDateString('sv-SE')
    let taskId = this.afs.createId();
    let data;
    data = {
      taskName,
      taskEditor,
      taskClient,
      workType,
      dueDate,
      fileName,
      fileUrl,
      status,
      taskDescription,
      taskId,
      editorId
    };
    const ref = this.afs.collection('assignments');
    return ref.doc(taskId).set(data)
    .then(() => {
      const ref = this.afs.collection('users');
      return ref.doc(editorId).update({
        pendingAssignments: firebase.firestore.FieldValue.increment(1),
        totalAssignments: firebase.firestore.FieldValue.increment(1),
      })
    })

  }

  sendMessageAdmin(from, email, subject, message){
    let data;
    let status = "Sent";
    let dateSent = + new Date();
    let flagged = false;
    let opened = false;
    let resolved = false;
    let msgData = [];
    data = {
      from,
      email,
      subject,
      message,
      status,
      dateSent,
      flagged,
      opened,
      resolved,
      msgData
    }
    
    const ref = this.afs.collection('adminmessages');
    
    Email.send({
      Host : 'smtp.gmail.com',
      Username : 'gfc.firebase@gmail.com',
      Password : 'yvmtwwbkzikmskdi',
      To : 'admin@clientmentorportal.com',
      From : email,
      Subject : 'New message alert',
      Body : `A message is sent to the admin<br/><br/>
          <b>From: </b>${from}<br />
          <b>Email: </b>${email}<br />
          <b>Subject: </b>${subject}<br />
          <b>Message:</b> <br />
          ${message? message: "No message"} <br><br>
          
          <b>~End of Message.~</b>`
      }).then(
        message => {
  
          if(message === 'OK')
            console.log( 'Successfully Sent!');
          else
          console.log('Error: '+ message);
  
            }, err => {console.log(err); }
      );
    return ref.add(data)
  }

  openedMsg(id) {
    const ref = this.afs.collection('adminmessages').doc(id);

    return ref.update({
      opened: true,
    })

  }
  replyMessageAdmin(from, adminReply,email,subject,id){
    let dateReplied = + new Date();
    let data = {
      from,
      email,
      subject,
      adminReply,
      dateReplied
    }
    const ref = this.afs.collection('adminmessages').doc(id);
    if (from == 'Admin') {
    Email.send({
      Host : 'smtp.gmail.com',
      Username : 'gfc.firebase@gmail.com',
      Password : 'yvmtwwbkzikmskdi',
      To : email,
      From : 'admin@clientmentorportal.com',
      Subject : subject,
      Body : `Admin replied to your message.<br/><br/>
          <b>Subject: </b>${subject}<br />
          <b>Message:</b> <br />
          ${adminReply? adminReply: "No message"} <br><br>
          
          <b>~End of Message.~</b>`
      }).then(
        message => {
  
          if(message === 'OK')
          console.log( 'Successfully Sent!');
          else
          console.log('Error: '+ message);
  
            }, err => {console.log(err); }
      );
          }
      
      return ref.update({
        msgData: firestore.FieldValue.arrayUnion(data),
        resolved: true
      })
  }
  deleteMessageDocument(id){
    const ref = this.afs.collection('adminmessages').doc(id);

    if(window.confirm("Are you sure you want to delete this?")) {
          return ref.delete()
        }      
  }
  deleteMessage(from, message,id){
    let messageData;
    messageData = {
        from: from,
        message: message,
        id:id
    }
    const ref = this.afs.collection('adminmessages').doc(id);
      
      return ref.update({
        messageData: firestore.FieldValue.arrayRemove(messageData)
      })
    }

  getAssignments(start: BehaviorSubject<string>) {
    return start.pipe(
      switchMap(startText => {
        const endText = startText + '\uf8ff';
        // console.log(startText);
        return this.afs.collection('assignments', ref => {
          return ref.orderBy('dueDate');
        }).snapshotChanges().pipe(
          debounceTime(100),
          distinctUntilChanged(),
          map((changes: any) => {return changes.map(c => {
            const data = c.payload.doc.data();
            const id = c.payload.doc.id;
            if (typeof data !== typeof undefined && data.uid === this.fsAuth.auth.currentUser.uid) {
              return;
            } else {
              return {id, ...data};
            }
          });})
        );
      })
    );
  }

  getPendingAssignments(start: BehaviorSubject<string>) {
    return start.pipe(
      switchMap(startText => {
        const endText = startText + '\uf8ff';
        // console.log(startText);
        return this.afs.collection('assignments', ref => {
          return ref.where('status','==','Pending').orderBy('dueDate')
        }).snapshotChanges().pipe(
          debounceTime(100),
          distinctUntilChanged(),
          map((changes: any) => {return changes.map(c => {
            const data = c.payload.doc.data();
            const id = c.payload.doc.id;
            if (typeof data !== typeof undefined && data.uid === this.fsAuth.auth.currentUser.uid) {
              return;
            } else {
              return {id, ...data};
            }
          });})
        );
      })
    );
  }

  getCompletedAssignments(start: BehaviorSubject<string>) {
    return start.pipe(
      switchMap(startText => {
        const endText = startText + '\uf8ff';
        // console.log(startText);
        return this.afs.collection('assignments', ref => {
          return ref.where('status','==','Completed').orderBy('dueDate')
        }).snapshotChanges().pipe(
          debounceTime(100),
          distinctUntilChanged(),
          map((changes: any) => {return changes.map(c => {
            const data = c.payload.doc.data();
            const id = c.payload.doc.id;
            if (typeof data !== typeof undefined && data.uid === this.fsAuth.auth.currentUser.uid) {
              return;
            } else {
              return {id, ...data};
            }
          });})
        );
      })
    );
  }

  getLateAssignments(start: BehaviorSubject<string>) {
    return start.pipe(
      switchMap(startText => {
        const endText = startText + '\uf8ff';
        // console.log(startText);
        return this.afs.collection('assignments', ref => {
          return ref.where('status','==','Late').orderBy('dueDate')
        }).snapshotChanges().pipe(
          debounceTime(100),
          distinctUntilChanged(),
          map((changes: any) => {return changes.map(c => {
            const data = c.payload.doc.data();
            const id = c.payload.doc.id;
            if (typeof data !== typeof undefined && data.uid === this.fsAuth.auth.currentUser.uid) {
              return;
            } else {
              return {id, ...data};
            }
          });})
        );
      })
    );
  }

  getMyAssignments(email) {
    return this.afs.collection('assignments', ref => {
      return ref.where('taskEditor','==',email)
    }).snapshotChanges().pipe(
      debounceTime(100),
      distinctUntilChanged(),
      map((changes: any) => {return changes.map(c => {
        const data = c.payload.doc.data();
        const id = c.payload.doc.id;
        if (typeof data !== typeof undefined && data.uid === this.fsAuth.auth.currentUser.uid) {
          return;
        } else {
          return {id, ...data};
        }
      });})
    );
  }

  getMySentAssignments(email) {
    return this.afs.collection('assignments', ref => {
      return ref.where('editorAssigned','==',email).where('status','==','Sent');
    }).snapshotChanges().pipe(
      debounceTime(100),
      distinctUntilChanged(),
      map((changes: any) => {return changes.map(c => {
        const data = c.payload.doc.data();
        const id = c.payload.doc.id;
        if (typeof data !== typeof undefined && data.uid === this.fsAuth.auth.currentUser.uid) {
          return;
        } else {
          return {id, ...data};
        }
      });})
    );
  }
  getMyOngoingAssignments(email) {
    return this.afs.collection('assignments', ref => {
      return ref.where('editorAssigned','==',email).where('status','==','On Going');
    }).snapshotChanges().pipe(
      debounceTime(100),
      distinctUntilChanged(),
      map((changes: any) => {return changes.map(c => {
        const data = c.payload.doc.data();
        const id = c.payload.doc.id;
        if (typeof data !== typeof undefined && data.uid === this.fsAuth.auth.currentUser.uid) {
          return;
        } else {
          return {id, ...data};
        }
      });})
    );
  }

  getMyCompletedAssignments(email) {
    return this.afs.collection('assignments', ref => {
      return ref.where('editorAssigned','==',email);
    }).snapshotChanges().pipe(
      debounceTime(100),
      distinctUntilChanged(),
      map((changes: any) => {return changes.map(c => {
        const data = c.payload.doc.data();
        const id = c.payload.doc.id;
        if (typeof data !== typeof undefined && data.uid === this.fsAuth.auth.currentUser.uid) {
          return;
        } else {
          return {id, ...data};
        }
      });})
    );
  }

  getMyAssignedTasks(email) {
    return this.afs.collection('assignments', ref => {
      return ref.where('clientEmail','==',email).where('status','==','On Going');
    }).snapshotChanges().pipe(
      debounceTime(100),
      distinctUntilChanged(),
      map((changes: any) => {return changes.map(c => {
        const data = c.payload.doc.data();
        const id = c.payload.doc.id;
        if (typeof data !== typeof undefined && data.uid === this.fsAuth.auth.currentUser.uid) {
          return;
        } else {
          return {id, ...data};
        }
      });})
    );
  }

  
  getMyCompletedAssignedTasks(email) {
    return this.afs.collection('assignments', ref => {
      return ref.where('clientEmail','==',email).where('status','==','Completed');
    }).snapshotChanges().pipe(
      debounceTime(100),
      distinctUntilChanged(),
      map((changes: any) => {return changes.map(c => {
        const data = c.payload.doc.data();
        const id = c.payload.doc.id;
        if (typeof data !== typeof undefined && data.uid === this.fsAuth.auth.currentUser.uid) {
          return;
        } else {
          return {id, ...data};
        }
      });})
    );
  }
  

  getThisTask(id) {
    return this.afs.collection('assignments').doc(id).valueChanges()
  }

  sendRevisionToAssignedTask(id,message) {
    const ref = this.afs.collection('assignments').doc(id);
    let data;
    data = {
      message: message,
    }
    return ref.update({
      revisions: firestore.FieldValue.arrayUnion(data),
    })
  }

  deleteAssignment(id) {
    return this.afs.collection('assignments').doc(id).delete()
  }

  public completeAssignment(id,editorId) {
    return this.afs.collection('assignments').doc(id).update({
      status: 'Completed'
    }).then(() => {
      return this.afs.collection('users').doc(editorId).update({
        completedAssignments: firebase.firestore.FieldValue.increment(1),
        pendingAssignments: firebase.firestore.FieldValue.increment(-1),
      })
    })
  }
  public updateAssignment(id,taskName,taskEditor,taskClient,workType,dueDate,status,taskDescription) {
    let data;
    data = {
      taskName,
      taskEditor,
      taskClient,
      workType,
      dueDate,
      status,
      taskDescription,
    };
    return this.afs.collection('assignments').doc(id).update(data)
  }

  public revertAssignment(id,editorId) {
    return this.afs.collection('assignments').doc(id).update({
      status: 'Pending'
    }).then(() => {
      return this.afs.collection('users').doc(editorId).update({
        completedAssignments: firebase.firestore.FieldValue.increment(-1),
        pendingAssignments: firebase.firestore.FieldValue.increment(1),
      })
    })
  }

  getAssignment(id) {
     
     return this.afs.collection('assignments',ref => ref.where('taskId','==',id))
     .doc(id)
     .snapshotChanges()
     .pipe(
       map((doc: any) => {
         // console.log(doc)
         return { id: doc.payload.id, ...doc.payload.data() };
       })
     );
  }

  getThisAssignment(date) {
    return this.afs.collection('assignments', ref => {
      return ref.where('dueDate','==',date);
    }).snapshotChanges().pipe(
      debounceTime(100),
      distinctUntilChanged(),
      map((changes: any) => {return changes.map(c => {
        const data = c.payload.doc.data();
        const id = c.payload.doc.id;
        if (typeof data !== typeof undefined && data.uid === this.fsAuth.auth.currentUser.uid) {
          return;
        } else {
          return {id, ...data};
        }
      });})
    );
  }

  getThisAssignmentTimeline(date) {
    return this.afs.collection('assignments', ref => {
      return ref.where('projectTimeline','==',date);
    }).snapshotChanges().pipe(
      debounceTime(100),
      distinctUntilChanged(),
      map((changes: any) => {return changes.map(c => {
        const data = c.payload.doc.data();
        const id = c.payload.doc.id;
        if (typeof data !== typeof undefined && data.uid === this.fsAuth.auth.currentUser.uid) {
          return;
        } else {
          return {id, ...data};
        }
      });})
    );
  }

  getAllAssignments() {
    return this.afs.collection('assignments', ref => {
      return ref.orderBy('dueDate');
    }).snapshotChanges().pipe(
      debounceTime(100),
      distinctUntilChanged(),
      map((changes: any) => {return changes.map(c => {
        const data = c.payload.doc.data();
        const id = c.payload.doc.id;
        if (typeof data !== typeof undefined && data.uid === this.fsAuth.auth.currentUser.uid) {
          return;
        } else {
          return {id, ...data};
        }
      });})
    );
  }

}
