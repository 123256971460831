import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject, of } from 'rxjs';
import { switchMap, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
@Injectable({
  providedIn: 'root'
})
export class GetUsersService {
email;
constructor(
  private afs: AngularFirestore,
  private afAuth: AngularFireAuth,
) { }


  getUsers(start: BehaviorSubject<string>) {
    return start.pipe(
      switchMap(startText => {
        const endText = startText + '\uf8ff';
        // console.log(startText);
        if (startText) {
          return this.afs.collection('users', ref => {
            return ref.orderBy('email').limit(10).startAt(startText).endAt(endText);
          })
          .snapshotChanges().pipe(
            debounceTime(100),
            distinctUntilChanged(),
            map((changes: any) => {
              return changes.map(
                c => {
                  const data = c.payload.doc.data();
                  const id = c.payload.doc.id;
                  if (typeof data !== typeof undefined && data.uid === this.afAuth.auth.currentUser.uid) {
                    return;
                  } else {
                    return {id, ...data};
                  }
                }
              );
            })
          );
        } else {
          return of(null);
        }
      })
    );
  }

  // getEditors(start: BehaviorSubject<string>) {
  //   return start.pipe(
  //     switchMap(startText => {
  //       const endText = startText + '\uf8ff';
  //       // console.log(startText);
  //       if (startText) {
  //         return this.afs.collection('users', ref => {
  //           return ref.where('role','==','Editor').startAt(startText).endAt(endText);
  //         })
  //         .snapshotChanges().pipe(
  //           debounceTime(100),
  //           distinctUntilChanged(),
  //           map((changes: any) => {
  //             return changes.map(
  //               c => {
  //                 const data = c.payload.doc.data();
  //                 const id = c.payload.doc.id;
  //                 if (typeof data !== typeof undefined && data.uid === this.afAuth.auth.currentUser.uid) {
  //                   return;
  //                 } else {
  //                   return {id, ...data};
  //                 }
  //               }
  //             );
  //           })
  //         );
  //       } else {
  //         return of(null);
  //       }
  //     })
  //   );
  // }

  getEditors(start: BehaviorSubject<string>) {
    return start.pipe(
      switchMap(startText => {
        return this.afs.collection('users', ref => {
          return ref.where('role','==','Editor')
        }).snapshotChanges().pipe(
          debounceTime(100),
          distinctUntilChanged(),
          map((changes: any) => {return changes.map(c => {
            const data = c.payload.doc.data();
            const id = c.payload.doc.id;
            if (typeof data !== typeof undefined && data.uid === this.afAuth.auth.currentUser.uid) {
              return;
            } else {
              return {id, ...data};
            }
          });})
        );
      })
    );
  }

  getTopPendingEditors(start: BehaviorSubject<string>) {
    return start.pipe(
      switchMap(startText => {
        return this.afs.collection('users', ref => {
          return ref.where('role','==','Editor').orderBy('pendingAssignments','desc').limit(3)
        }).snapshotChanges().pipe(
          debounceTime(100),
          distinctUntilChanged(),
          map((changes: any) => {return changes.map(c => {
            const data = c.payload.doc.data();
            const id = c.payload.doc.id;
            if (typeof data !== typeof undefined && data.uid === this.afAuth.auth.currentUser.uid) {
              return;
            } else {
              return {id, ...data};
            }
          });})
        );
      })
    );
  }

  getTopLateEditors(start: BehaviorSubject<string>) {
    return start.pipe(
      switchMap(startText => {
        return this.afs.collection('users', ref => {
          return ref.where('role','==','Editor').orderBy('lateAssignments','desc').limit(3)
        }).snapshotChanges().pipe(
          debounceTime(100),
          distinctUntilChanged(),
          map((changes: any) => {return changes.map(c => {
            const data = c.payload.doc.data();
            const id = c.payload.doc.id;
            if (typeof data !== typeof undefined && data.uid === this.afAuth.auth.currentUser.uid) {
              return;
            } else {
              return {id, ...data};
            }
          });})
        );
      })
    );
  }

  getTopCompletedEditors(start: BehaviorSubject<string>) {
    return start.pipe(
      switchMap(startText => {
        return this.afs.collection('users', ref => {
          return ref.where('role','==','Editor').orderBy('completedAssignments','desc').limit(3)
        }).snapshotChanges().pipe(
          debounceTime(100),
          distinctUntilChanged(),
          map((changes: any) => {return changes.map(c => {
            const data = c.payload.doc.data();
            const id = c.payload.doc.id;
            if (typeof data !== typeof undefined && data.uid === this.afAuth.auth.currentUser.uid) {
              return;
            } else {
              return {id, ...data};
            }
          });})
        );
      })
    );
  }

  getTop10CompletedEditorsDescending(start: BehaviorSubject<string>) {
    return start.pipe(
      switchMap(startText => {
        return this.afs.collection('users', ref => {
          return ref.where('role','==','Editor').orderBy('completedAssignments','desc').limit(10)
        }).snapshotChanges().pipe(
          debounceTime(100),
          distinctUntilChanged(),
          map((changes: any) => {return changes.map(c => {
            const data = c.payload.doc.data();
            const id = c.payload.doc.id;
            if (typeof data !== typeof undefined && data.uid === this.afAuth.auth.currentUser.uid) {
              return;
            } else {
              return {id, ...data};
            }
          });})
        );
      })
    );
  }

  getTop10CompletedEditorsAscending(start: BehaviorSubject<string>) {
    return start.pipe(
      switchMap(startText => {
        return this.afs.collection('users', ref => {
          return ref.where('role','==','Editor').orderBy('completedAssignments','asc').limit(10)
        }).snapshotChanges().pipe(
          debounceTime(100),
          distinctUntilChanged(),
          map((changes: any) => {return changes.map(c => {
            const data = c.payload.doc.data();
            const id = c.payload.doc.id;
            if (typeof data !== typeof undefined && data.uid === this.afAuth.auth.currentUser.uid) {
              return;
            } else {
              return {id, ...data};
            }
          });})
        );
      })
    );
  }

  getTop10TotalEditorsDescending(start: BehaviorSubject<string>) {
    return start.pipe(
      switchMap(startText => {
        return this.afs.collection('users', ref => {
          return ref.where('role','==','Editor').orderBy('totalAssignments','desc').limit(10)
        }).snapshotChanges().pipe(
          debounceTime(100),
          distinctUntilChanged(),
          map((changes: any) => {return changes.map(c => {
            const data = c.payload.doc.data();
            const id = c.payload.doc.id;
            if (typeof data !== typeof undefined && data.uid === this.afAuth.auth.currentUser.uid) {
              return;
            } else {
              return {id, ...data};
            }
          });})
        );
      })
    );
  }

  getTop10TotalEditorsAscending(start: BehaviorSubject<string>) {
    return start.pipe(
      switchMap(startText => {
        return this.afs.collection('users', ref => {
          return ref.where('role','==','Editor').orderBy('totalAssignments','asc').limit(10)
        }).snapshotChanges().pipe(
          debounceTime(100),
          distinctUntilChanged(),
          map((changes: any) => {return changes.map(c => {
            const data = c.payload.doc.data();
            const id = c.payload.doc.id;
            if (typeof data !== typeof undefined && data.uid === this.afAuth.auth.currentUser.uid) {
              return;
            } else {
              return {id, ...data};
            }
          });})
        );
      })
    );
  }

  getTop10LateEditorsDescending(start: BehaviorSubject<string>) {
    return start.pipe(
      switchMap(startText => {
        return this.afs.collection('users', ref => {
          return ref.where('role','==','Editor').orderBy('lateAssignments','desc').limit(10)
        }).snapshotChanges().pipe(
          debounceTime(100),
          distinctUntilChanged(),
          map((changes: any) => {return changes.map(c => {
            const data = c.payload.doc.data();
            const id = c.payload.doc.id;
            if (typeof data !== typeof undefined && data.uid === this.afAuth.auth.currentUser.uid) {
              return;
            } else {
              return {id, ...data};
            }
          });})
        );
      })
    );
  }

  getTop10LateEditorsAscending(start: BehaviorSubject<string>) {
    return start.pipe(
      switchMap(startText => {
        return this.afs.collection('users', ref => {
          return ref.where('role','==','Editor').orderBy('lateAssignments','asc').limit(10)
        }).snapshotChanges().pipe(
          debounceTime(100),
          distinctUntilChanged(),
          map((changes: any) => {return changes.map(c => {
            const data = c.payload.doc.data();
            const id = c.payload.doc.id;
            if (typeof data !== typeof undefined && data.uid === this.afAuth.auth.currentUser.uid) {
              return;
            } else {
              return {id, ...data};
            }
          });})
        );
      })
    );
  }


  getTopTotalEditors(start: BehaviorSubject<string>) {
    return start.pipe(
      switchMap(startText => {
        return this.afs.collection('users', ref => {
          return ref.where('role','==','Editor').orderBy('totalAssignments','desc').limit(3)
        }).snapshotChanges().pipe(
          debounceTime(100),
          distinctUntilChanged(),
          map((changes: any) => {return changes.map(c => {
            const data = c.payload.doc.data();
            const id = c.payload.doc.id;
            if (typeof data !== typeof undefined && data.uid === this.afAuth.auth.currentUser.uid) {
              return;
            } else {
              return {id, ...data};
            }
          });})
        );
      })
    );
  }



  getUsersList(start: BehaviorSubject<string>) {
    return start.pipe(
      switchMap(startText => {
        return this.afs.collection('users', ref => {
          return ref.orderBy('email');
        }).snapshotChanges().pipe(
          debounceTime(100),
          distinctUntilChanged(),
          map((changes: any) => {return changes.map(c => {
            const data = c.payload.doc.data();
            const id = c.payload.doc.id;
            if (typeof data !== typeof undefined && data.uid === this.afAuth.auth.currentUser.uid) {
              return;
            } else {
              return {id, ...data};
            }
          });})
        );
      })
    );
  }

  getMessages(start: BehaviorSubject<string>) {
    return start.pipe(
      switchMap(startText => {
        return this.afs.collection('adminmessages', ref => {
          return ref.where('resolved','==',false).orderBy('dateSent', 'desc')
        }).snapshotChanges().pipe(
          debounceTime(100),
          distinctUntilChanged(),
          map((changes: any) => {return changes.map(c => {
            const data = c.payload.doc.data();
            const id = c.payload.doc.id;
            if (typeof data !== typeof undefined && data.uid === this.afAuth.auth.currentUser.uid) {
              return;
            } else {
              return {id, ...data};
            }
          });})
        );
      })
    );
  }

  getResolvedMessages(start: BehaviorSubject<string>) {
    return start.pipe(
      switchMap(startText => {
        return this.afs.collection('adminmessages', ref => {
          return ref.where('resolved','==',true).orderBy('dateSent', 'desc')
        }).snapshotChanges().pipe(
          debounceTime(100),
          distinctUntilChanged(),
          map((changes: any) => {return changes.map(c => {
            const data = c.payload.doc.data();
            const id = c.payload.doc.id;
            if (typeof data !== typeof undefined && data.uid === this.afAuth.auth.currentUser.uid) {
              return;
            } else {
              return {id, ...data};
            }
          });})
        );
      })
    );
  }

  getMessage(id) {
    return this.afs.collection('adminmessages')
      .doc(id)
      .snapshotChanges()
      .pipe(
        map((doc: any) => {
          // console.log(doc)
          return { id: doc.payload.id, ...doc.payload.data() };
        })
      );
  }

  public flagMessage(id: string, flagged: boolean) {

    return this.afs.collection('adminmessages').doc(id).update({
      flagged: flagged,
     })
   
  }
  
  getFlaggedMessages(start: BehaviorSubject<string>) {
    return start.pipe(
      switchMap(startText => {
        return this.afs.collection('adminmessages', ref => {
          return ref.where('flagged','==',true).orderBy('dateSent', 'desc')
        }).snapshotChanges().pipe(
          debounceTime(100),
          distinctUntilChanged(),
          map((changes: any) => {return changes.map(c => {
            const data = c.payload.doc.data();
            const id = c.payload.doc.id;
            if (typeof data !== typeof undefined && data.uid === this.afAuth.auth.currentUser.uid) {
              return;
            } else {
              return {id, ...data};
            }
          });})
        );
      })
    );
  }

  getAdminMessages(start: BehaviorSubject<string>,user) {
    return start.pipe(
      switchMap(startText => {
        return this.afs.collection('adminmessages', ref => {
          return ref.where('email','==',user)
        }).snapshotChanges().pipe(
          debounceTime(100),
          distinctUntilChanged(),
          map((changes: any) => {return changes.map(c => {
            const data = c.payload.doc.data();
            const id = c.payload.doc.id;
            if (typeof data !== typeof undefined && data.uid === this.afAuth.auth.currentUser.uid) {
              return;
            } else {
              return {id, ...data};
            }
          });})
        );
      })
    );
  }
/*
  getAdminReply(start: BehaviorSubject<string>,email) {
    return start.pipe(
      switchMap(startText => {
        return this.afs.collection('adminReplies', ref => {
          return ref.orderBy('subject').where('email','==',this.email)
        }).snapshotChanges().pipe(
          debounceTime(100),
          distinctUntilChanged(),
          map((changes: any) => {return changes.map(c => {
            const data = c.payload.doc.data();
            const id = c.payload.doc.id;
            if (typeof data !== typeof undefined && data.uid === this.afAuth.auth.currentUser.uid) {
              return;
            } else {
              return {id, ...data};
            }
          });})
        );
      })
    );
  }

*/
}
