import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { UserProfile } from 'src/app/utils/user-profile.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-manual',
  templateUrl: './manual.component.html',
  styleUrls: ['./manual.component.scss']
})
export class ManualComponent implements OnInit {
  profileSub: Subscription;
  currentUser;

  constructor(
    private profile: UserProfile,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.profileSub = this.profile.stream().subscribe(r => {
      this.currentUser = r; console.log(this.currentUser)
    });
  }

  openDialogChatManual() {
    this.dialog.open(ChatManualDialog);
  }

  openDialogProjectManual() {
    this.dialog.open(ProjectManualDialog);
  }

}

@Component({
  selector: 'chat-manual-dialog',
  templateUrl: '/manual-dialogs/chat-manual-dialog.html',
  styleUrls: ['/manual-dialogs/manual-dialogs.scss']
})
export class ChatManualDialog {}

@Component({
  selector: 'project-manual-dialog',
  templateUrl: '/manual-dialogs/project-manual-dialog.html',
  styleUrls: ['/manual-dialogs/manual-dialogs.scss']
})
export class ProjectManualDialog {}