import { Component, OnInit } from '@angular/core';
import { Params, ActivatedRoute } from '@angular/router';
import {FormControl, Validators} from '@angular/forms';
import { AssignmentService } from 'src/app/services/assignment/assignment.service';
import { switchMap } from 'rxjs/operators';
import { GetUsersService } from 'src/app/services/get-users.service';
@Component({
  selector: 'app-user-inquiries-details',
  templateUrl: './user-inquiries-details.component.html',
  styleUrls: ['./user-inquiries-details.component.scss']
})
export class UserInquiriesDetailsComponent implements OnInit {
reply: boolean;
message = new FormControl('', Validators.required);
msgData;
dateToday = + new Date();

  constructor(
    private as: AssignmentService,
    private route: ActivatedRoute,
    private userService: GetUsersService,
    private getUsersService: GetUsersService
  ) { }

  ngOnInit() {
    this.reply = false;

    this.route.params.pipe(
      switchMap((params: Params) => {
        return this.userService.getMessage(params['id'])
      })
    ).subscribe((res) => {
      this.msgData = res;
      this.as.openedMsg(res.id)
      console.log(res)
    })
  }

  toggleReply() {
    this.reply = !this.reply;
  }
  flagMessage(id,flagged) {
    this.getUsersService.flagMessage(id,!flagged);
  }
  replyToMsg(email, subject, id) {
    this.reply = !this.reply;
    console.log(this.message.value);
    this.as.replyMessageAdmin('Admin',this.message.value, email, subject, id).then(() => {
      window.alert('You have successfully replied to ' + email);
    })

  }

}
