import { Component, OnInit, Inject } from '@angular/core';
import { UserProfile } from 'src/app/utils/user-profile.service';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import { AssignmentService } from 'src/app/services/assignment/assignment.service';
import { AngularFireAuth } from '@angular/fire/auth';
import '../../../../services/mail/smtp.js';
declare let Email: any;
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-my-projects',
  templateUrl: './my-projects.component.html',
  styleUrls: ['./my-projects.component.scss']
})
export class MyProjectsComponent implements OnInit {
  currentUSerSubscription: Subscription;
  currentUser;
  assignments: Observable<any>;
  myAssignments: Observable<any>;
  user$;
  get auth() { return this.profile.auth; }

  constructor(
    private profile: UserProfile,
    private as: AssignmentService,
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.currentUSerSubscription = this.profile
      .stream().subscribe(
        r => {
          this.currentUser = r;
        }
      );
      this.user$ = this.afAuth.auth.currentUser;
      console.log(this.user$.email);
      this.assignments = this.as.getMyAssignedTasks(this.user$.email);
      this.assignments.subscribe(myAssignments => {
        this.myAssignments = myAssignments;
      })
  }

  finishAssignment(editorAssigned, _id: string){
    Email.send({
      Host : 'smtp.gmail.com',
      Username : 'gfc.firebase@gmail.com',
      Password : 'yvmtwwbkzikmskdi',
      To : editorAssigned,
      From : this.user$.email,
      Subject : 'Assignment Accepted',
      Body : `Editor ${this.user$.displayName} has completed the assignment!.`
      }).then(
        message => {
  
          if(message === 'OK')
              alert( 'Successfully Sent!');
          else
            alert('Error: '+ message);
        }
      );
    return this.afs.doc(`assignments/${_id}`).update({status: "Completed",projectStatus: "Completed"});
  }
  openDialog(id) {
    this.dialog.open(DialogElementsExampleDialog, {
      data: {
        id: id,
      }
    });
  }
}

@Component({
  selector: 'dialog-elements-example-dialog',
  template: `
  <h1 mat-dialog-title>Revisions</h1>
<div mat-dialog-content>
<h4>My Revisions</h4>
<div *ngIf="myAssignments">
<div *ngFor="let rev of myAssignments?.revisions; let i = index">
<h6>Revision {{i+1}}</h6>
<ul>
<li>{{rev.message}}</li>
</ul>
</div>
<h6>Add Revision</h6>
<form novalidate #loginForm="ngForm" (ngSubmit)="sendRevision()">
<input
placeholder="Enter revision message"
type="text"
[(ngModel)]="revision.message"
 name="message"
#message="ngModel"
required
/>
<br>
<button mat-button type="submit">Send</button>

</form>
</div>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Close</button>
</div>
  `,
})
export class DialogElementsExampleDialog {
  revision = { message:''};
  user$;
  assignments: Observable<any>;
  myAssignments: Observable<any>;
  constructor(
    private as: AssignmentService,
    private afAuth: AngularFireAuth,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }
 
  ngOnInit() {
    console.log(this.data)
    this.user$ = this.afAuth.auth.currentUser;
    this.assignments = this.as.getThisTask
    (this.data.id);
    this.assignments.subscribe(myAssignments => {
      this.myAssignments = myAssignments; console.log(myAssignments)
    })
  }
  sendRevision(id) {
    console.log(this.revision.message)
    this.as.sendRevisionToAssignedTask(this.data.id,this.revision.message);
  }
}