import { Component, OnInit, Inject } from '@angular/core';
import { $animations } from './login-animations';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { $pages } from './login-pages';
import { $providers } from './login-providers';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { UserProfile } from 'src/app/utils/user-profile.service';
import './../../../services/mail/smtp.js';
import { AssignmentService } from 'src/app/services/assignment/assignment.service';
declare let Email: any;
import { AngularFirestore } from '@angular/fire/firestore';
import { ChatService } from 'src/app/services/chat.service';
import { Observable } from 'rxjs';
import * as firebase from 'firebase';
import { firestore } from 'firebase';

export type loginAction = 'message' | 'register' | 'signIn' | 'forgotPassword' | 'changePassword' | 'changeEmail' | 'delete' | 'signOut';

export class Role {
  constructor(public roleId: string, public roleName: string) {
  }
}
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [$animations]
})
export class LoginComponent {
  myChats$: Observable<any>;
  appearance = "outline";
  readonly providers = $providers;
  private pages = $pages;
  page: loginAction;
  private code: string;

  readonly form: FormGroup;
  private name: FormControl;
  private email: FormControl;
  private subject: FormControl;
  private message: FormControl;
  private role: FormControl;
  private password: FormControl;
  private newEmail: FormControl;
  private newPassword: FormControl;
  private confirmPassword: FormControl;

  public hidePassword = true;
  public error = null;
  public progress = false;
  allRoles = [
    new Role('ed', 'Editor'),
    new Role('cl', 'Client'),
  ];


  get auth() { return this.profile.auth; }

  constructor(private as: AssignmentService, 
    private profile: UserProfile, 
    private ref: MatDialogRef<LoginComponent>,
    @Inject(MAT_DIALOG_DATA) private action: loginAction,
    private af: AngularFirestore,
    public cs: ChatService,
    ) {
    // Form controls
    this.name = new FormControl(null, Validators.required);
    this.email = new FormControl(null, [Validators.required, Validators.email]);
    this.role = new FormControl(null, [Validators.required]);
    this.subject = new FormControl(null, [Validators.required]);
    this.message = new FormControl(null, [Validators.required]);
    this.password = new FormControl(null, Validators.required);
    this.newEmail = new FormControl(null, [Validators.required, Validators.email]);
    this.newPassword = new FormControl(null, Validators.required);
    this.confirmPassword = new FormControl(null, Validators.required);

    // Empty form group
    this.form = new FormGroup({});

    // Populates the form according to the page
    this.switchPage(this.page = action);
  }

  get currentPage() { return this.pages[this.page || 'signIn']; }

  private switchPage(page: loginAction) {

    // Removes all the controls from the form group
    Object.keys(this.form.controls).forEach(control => {
      this.form.removeControl(control);
    });

    // Add the relevant controls to the form according to selected page
    switch (this.page = page) {

      case 'register':
        this.form.addControl('name', this.name);
        this.form.addControl('email', this.email);
        this.form.addControl('role', this.role);
        this.form.addControl('password', this.password);
        break;

      default:
      case 'signIn':
        this.form.addControl('email', this.email);
        this.form.addControl('password', this.password);
        break;

      case 'forgotPassword':
        this.form.addControl('email', this.email);
        break;
      /*
            case 'resetPassword':
            this.form.addControl('newPassword', this.newPassword);
            break;
      */
      case 'changePassword':
        this.form.addControl('password', this.password);
        this.form.addControl('newPassword', this.newPassword);
        this.form.addControl('confirmPassword', this.confirmPassword);
        break;

      case 'changeEmail':
        this.form.addControl('password', this.password);
        this.form.addControl('newEmail', this.newEmail);
        break;

      case 'delete':
        this.form.addControl('password', this.password);
        break;

      case 'message':
        this.form.addControl('name', this.name);
        this.form.addControl('email', this.email);
        this.form.addControl('subject', this.subject);
        this.form.addControl('message', this.message);
    }
  }

  private showError(error: string) {

    switch(error){
      case 'auth/user-not-found':
        this.error = "Email not found";
        console.log(error)
        this.progress = false;
        setTimeout(() => this.error = null, 5000);
        break;
      case 'auth/wrong-password':
        this.error = "Wrong password";
        this.progress = false;
        setTimeout(() => this.error = null, 5000);
        break;
    }
  }

  public activate(action: loginAction) {

    this.progress = true;

    switch (action) {

      default:
      case 'signIn':
        this.signIn(this.email.value, this.password.value);
        break;

      case 'register':
        this.registerNew(this.email.value, this.password.value, this.name.value, this.role.value);
        break;

      case 'forgotPassword':
        this.forgotPassword(this.email.value);
        break;
      /*
            case 'resetPassword':
            this.resetPassword( this.code, this.newPassword.value );
            break;
      */
      case 'changePassword':
        this.updatePassword(this.password.value, this.newPassword.value);
        break;

      case 'changeEmail':
        this.updateEmail(this.password.value, this.newEmail.value);
        break;

      case 'delete':
        this.deleteAccount(this.password.value,this.profile.uid);
        break;
      
      case 'message':
        this.as.sendMessageAdmin(this.name, this.email, this.subject, this.message)
    }
  }

  private registerNew(email: string, password: string, name: string, role: string) {
    // // Registering a new user with a email/password
    this.auth.adminCreateUser(email, password, name, role).then(
      (r) => {
        console.log(r);
        this.ref.close(null);
      }
    ).catch(e => console.log(e));
    this.auth.registerNew(email, password, name, role)
      .then(user => this.profile.register(user, role)
        // Closes the dialog returning the user
        .then(() => this.ref.close(user))
      )// .then(() => this.auth.setUserData(email, role).then(r => console.log(r)))
      // Dispays the error code, eventually
      .catch(error => this.showError(error.code));
  }

  private signIn(email: string, password: string) {
    // Sign-in using email/password
    this.auth.signIn(email, password)
      // Closes the dialog returning the user
      .then(user => this.ref.close(user))
      // Dispays the error code, eventually
      .catch(error => this.showError(error.code));
  }

  private signInWith(provider: string, role: string) {
    // Signing-in with a provider
    this.auth.signInWith(provider)
      // Creates the new user profile if needed, keeps the existing one otherwise
      .then(user => this.profile.register(user, role)
        // Closes the dialog returning the user
        .then(() => this.ref.close(user))
      )
      // Dispays the error code, eventually
      .catch(error => this.showError(error.code));
  }

  private forgotPassword(email: string) {

    this.auth.sendPasswordResetEmail(email)
      // Closes the dialog returning null
      .then(() => this.ref.close(null))
      // Dispays the error code, eventually
      .catch(error => this.showError(error.code));
  }
  /*
    private resetPassword(code: string, newPassword: string) {

      this.auth.confirmPasswordReset(code, newPassword)
        //.then( () => this.reportSuccess('Reset to a new password', 'signIn') )
        // Dispays the error code, eventually
        .catch( error => this.showError(error.code) );
    }
  */

  private updateEmail(password: string, newEmail: string) {
    // Refreshes the authentication
    this.auth.refresh(password)
      // Updates the email returning the new user object
      .then(user => user.updateEmail(newEmail).then(() => this.ref.close(user)))
      // Dispays the error code, eventually
      .catch(error => this.showError(error.code));
  }

  private updatePassword(password: string, newPassword: string) {
    // Refreshes the authentication
    this.auth.refresh(password)
      // Updates the password returning the new user object
      .then(user => user.updatePassword(newPassword).then(() => this.ref.close(user)))
      // Dispays the error code, eventually
      .catch(error => this.showError(error.code));
  }
/*
  private deleteAccount(password: string) {
    // Refreshes the authentication
    this.auth.refresh(password)
      // Deletes the user profile first
      .then(user => this.profile.delete()
        // Deletes the user object next
        .then(() => user.delete())
      )
      // Closes the dialog returning null
      .then(() => this.ref.close(null))
      // Dispays the error code, eventually
      .catch(error => this.showError(error.code));
  }
  */
      private deleteAccount(password: string,id) {
/*
        this.myChats$ = this.cs.getMyChats(id);
        this.myChats$.subscribe(r => {
          for (let index = 0; index < r.length; index++) {
            console.log(r[index].id)
            const dRef = this.af.collection('chats').doc(r[index].id);
            dRef.update({
              [id]: firestore.FieldValue.delete(),
              members: firebase.firestore.FieldValue.arrayRemove(id),
              history: firebase.firestore.FieldValue.arrayRemove(id)
            })
          }
          
        })
        */
        
        this.auth.refresh(password)
          // Deletes the user profile first
          .then(user => this.profile.delete()
            // Deletes the user object next
            .then(() => user.delete())
          ).then(()=> this.af.collection(`users`, ref => ref.orderBy('displayName')).valueChanges().subscribe(users => {
            users.map((user: any) => {
              this.af.doc(`users/${id}`).delete()
                .catch(error => {console.log(error); })
                .then(() => console.log(`Deleting user (${id})`));
            });
          }))
          // Closes the dialog returning null
          .then(() => this.ref.close(null))
          // Dispays the error code, eventually
          .catch(error => this.showError(error.code));    
          
      }
/*
  private deleteAccount(password: string,id) {
    this.auth.refresh(password)
      // Deletes the user profile first
      .then(user => this.profile.delete()
        // Deletes the user object next
        .then(() => user.delete())
      ).then(()=> this.af.collection(`users`, ref => ref.orderBy('displayName')).valueChanges().subscribe(users => {
        users.map((user: any) => {
          this.af.doc(`users/${id}`).delete()
            .catch(error => {console.log(error); })
            .then(() => console.log(`Deleting user (${id})`));
        });
      }))
      // Closes the dialog returning null
      .then(() => this.ref.close(null))
      // Dispays the error code, eventually
      .catch(error => this.showError(error.code));    
  }
  */
}