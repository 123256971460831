import { Component, OnInit } from '@angular/core';
import { Params, ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { AssignmentService } from 'src/app/services/assignment/assignment.service';
import {MatDialog} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AngularFireStorage } from '@angular/fire/storage';

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss']
})
export class ProjectDetailsComponent implements OnInit {
assign;
  constructor(
    private route: ActivatedRoute,
    private as: AssignmentService,
    public dialog: MatDialog,
    public router: Router,
    private storage: AngularFireStorage
  ) { }

  ngOnInit() {
    this.route.params.pipe(
      switchMap((params: Params) => {
        return this.as.getAssignment(params['id'])
      })
    ).subscribe((res) => {
        this.assign = res;
        console.log(this.assign)        
    })
  }

  deleteAssignment(id,url) {
    if (confirm('Are you sure you want to delete this assignment?')) {
      // Save it!
      // this.as.deleteAssignment(id)
      this.storage.storage.refFromURL(url).delete()
      .then(() => {
      this.as.deleteAssignment(id)
      })
      .then(() => {
        this.router.navigate(['home/project-management'])
      })
    } else {
      // Do nothing!
      console.log('Thing was not saved to the database.');
    }
  }

  completeAssignment(id,editorId) {
    if (confirm('Are you sure you want to mark this assignment as Completed?')) {
      // Save it!
      this.as.completeAssignment(id,editorId)
    } else {
      // Do nothing!
      console.log('Thing was not saved to the database.');
    }
  }

  revertAssignment(id,editorId) {
    if (confirm('Are you sure you want to open this project again?')) {
      // Save it!
      this.as.revertAssignment(id,editorId)
    } else {
      // Do nothing!
      console.log('Thing was not saved to the database.');
    }
  }

  updateProject(id) {
    this.router.navigate(['home/edit-project/',id])
  }

}



