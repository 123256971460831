import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { GetUsersService } from 'src/app/services/get-users.service';

@Component({
  selector: 'app-editors',
  templateUrl: './editors.component.html',
  styleUrls: ['./editors.component.scss']
})
export class EditorsComponent implements OnInit {
  editors: any;
  editorList: any;
  startAt: BehaviorSubject<string | null> = new BehaviorSubject('');

  constructor(
    private getUsersService: GetUsersService,
  ) { }

  ngOnInit() {
   this.getUsersService.getEditors(this.startAt).subscribe((res) => {
     this.editors = res;
   })
  }

}
