import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { AssignmentService } from 'src/app/services/assignment/assignment.service';
import { UserProfile } from 'src/app/utils/user-profile.service';
import { GetUsersService } from 'src/app/services/get-users.service';

@Component({
  selector: 'app-project-management',
  templateUrl: './project-management.component.html',
  styleUrls: ['./project-management.component.scss']
})
export class ProjectManagementComponent implements OnInit {
  topPendingEditors: any;
  topLateEditors: any;
  topCompletedEditors: any;
  topTotalEditors: any;
  currentUSerSubscription: Subscription;
  totalAssignments: Observable<any>;
  myAssignments: Observable<any>;
  startAt: BehaviorSubject<string | null> = new BehaviorSubject('');
  totalAssigns: any;
  pendingAssigns = [];
  completedAssigns = [];
  myAssings: any;
  editors: any;
  type: any;
  data: any;
  options: any;
  lateAssigns = [];
  constructor(
    private as: AssignmentService,
    private profile: UserProfile,
    private getUsersService: GetUsersService,
  ) { }

  ngOnInit() {
    this.editors = this.getUsersService.getEditors(this.startAt);
    
    this.getUsersService.getTopPendingEditors(this.startAt).subscribe((res) => {
      this.topPendingEditors = res;
    })
    this.getUsersService.getTopLateEditors(this.startAt).subscribe((res) => {
      this.topLateEditors = res;
    })
    this.getUsersService.getTopCompletedEditors(this.startAt).subscribe((res) => {
      this.topCompletedEditors = res;
    })
    this.getUsersService.getTopTotalEditors(this.startAt).subscribe((res) => {
      this.topTotalEditors = res;
    })

    console.log(this.topPendingEditors)

    this.currentUSerSubscription = this.profile
      .stream().subscribe(
        r => {
          this.myAssignments = this.as.getMyAssignments(r.email);
          this.myAssignments.subscribe((assgn) => {
            this.myAssings = assgn
          })
        }
      );

    this.totalAssignments = this.as.getAssignments(this.startAt);

    this.totalAssignments.subscribe((res) => {
      this.totalAssigns = res;
      console.log(res)

      res.forEach(element => {
        if (element.status == 'Pending') {
          this.pendingAssigns.push(element);
          console.log('may pending')
        }

        if (element.status == 'Late') {
          this.lateAssigns.push(element);
          console.log('may pending')
        }

        if (element.status == 'Completed') {
          this.completedAssigns.push(element);
          console.log('may pending')
        }

        this.type = 'doughnut';
        this.data = {
          labels: ["Pending Assignments", "Late Assignments","Completed Assignments"],
          datasets: [
            {
              label: "Pending Assignments",
              data: [this.pendingAssigns.length,this.lateAssigns.length,this.completedAssigns.length],
              backgroundColor: [
                'yellow',
                'red',
                'green'
              ],
              borderCplor: [
                '#yellow',
                'red',
                'green'
              ],
            }
          ]
        };
        this.options = {
          responsive: true,
          maintainAspectRatio: true
        };
      });
    })
  }

}
