import { Component, OnInit, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AssignmentService } from 'src/app/services/assignment/assignment.service';
import { Subscription, Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { AlertService } from 'src/app/components/_alert';
import { UserProfile } from 'src/app/utils/user-profile.service';
import { GetUsersService } from 'src/app/services/get-users.service';
import { Model } from './message';
import { AngularFirestore } from '@angular/fire/firestore';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-lead-controls',
  templateUrl: './lead-controls.component.html',
  styleUrls: ['./lead-controls.component.scss']
})
export class LeadControlsComponent implements OnInit {
  items$: Observable<any[]>;
  model: Model = new Model();
  currentUser;
  currentUSerSubscription: Subscription;
  get auth() { return this.profile.auth; }
  user$;
  @Input() users: Observable<any>;
  constructor(
    private afs: AngularFirestore,
    private profile: UserProfile,
    private as: AssignmentService,
    private afAuth: AngularFireAuth,
    protected alertService: AlertService,
    private getUsersService: GetUsersService
  ) { }
    
  ngOnInit() {
    this.currentUSerSubscription = this.profile
    .stream().subscribe(
      r => {
        this.currentUser = r;
      }
    );
  this.user$ = this.afAuth.auth.currentUser;
  //this.items$ = this.afs.collection('adminReplies').valueChanges();
  this.items$ = this.afs.collection('adminReplies', ref => ref.where('email','==',this.user$.email)).valueChanges();
  }

  onSubmit(f: NgForm){
    this.as.sendMessageAdmin(this.currentUser.displayName, this.user$.email, this.model.subject, this.model.message)
    f.resetForm();
  }

}
