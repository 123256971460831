import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UserProfile } from 'src/app/utils/user-profile.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { map } from 'rxjs/operators';
import { UploadTask } from 'src/app/services/storage/upload-task';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {

  readonly photo$: Observable<string>;
  readonly form: FormGroup;
  public uploadTask: UploadTask;
  // Returns the AuthService
  private get auth(): AuthService { return this.profile.auth; }

  constructor(private profile: UserProfile, private storage: StorageService, private builder: FormBuilder) {

    // Builds the form controls group
    this.form = builder.group({
      'displayName': ['', Validators.required],
      'email': ['', Validators.email],
      'photoURL': [''],
      'bio': [''],
      role: [{value: '', disabled: true}]
    });

    // Loads the profile into the form once
    // this.profile.get().then(data => this.form.patchValue(data)).catch((err)=> {
    //   console.log(err)
    // })
    // Streams the profile photo
    this.photo$ = this.profile.stream().pipe(map(profile => !!profile ? profile.photoURL : ''));
  }

  // Updates the profile
  public save(): Promise<void> {

    return this.profile.update(this.form.value)
      .then(() => this.form.markAsPristine())
      .then(() => console.log('saved!'));
  }


  public uploadPhoto(file: File): Promise<void> {

    if (!file) { return Promise.resolve(null); }

    // Creates the uploading task, this will display the progress bar in the view
    return (this.uploadTask = this.storage.upload(`${this.auth.userId}/${file.name}`, file))
      // Returns the url
      .then(snap => snap.ref.getDownloadURL())
      // Updates the profile with the new url
      .then(photoURL => this.profile.update({ photoURL }))
      // Deletes the task object removing the progress bar from the view
      .then(() => (delete this.uploadTask, null));
  }

  public deletePhoto(): Promise<void> {

    // Deletes the file in the storage first
    return this.deleteFile()
      // Resets the photo url into the profile
      .then(() => this.profile.update({ photoURL: '' }));
  }

  private deleteFile(): Promise<void> {
    // Reads the profile to get the photo url
    return this.profile.get().then(profile => {
      // Skips then no file
      if (!profile || !profile.photoURL) { return null; }
      // Gets the storage ref from the url...
      const ref = this.storage.refFromURL(profile.photoURL);
      // ... and deletes the file
      return ref.delete();
    })
      // Ensure to proceed whatever error has been encountered
      .catch(e => null);
  }

}
