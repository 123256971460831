import { RootImageFallbackDirective } from './rootImageDirective.directive';
import { DropZoneDirective } from './dropZone.directive';
import { ImageFallbackDirective } from './image-fallback.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
@NgModule({
  declarations: [
    ImageFallbackDirective,
    DropZoneDirective,
    RootImageFallbackDirective,
    ImageFallbackDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ImageFallbackDirective,
    DropZoneDirective,
    RootImageFallbackDirective,
    ImageFallbackDirective
  ]
})
export class SharedModule { }
