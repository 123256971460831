import { Directive, Input, ElementRef, HostListener } from '@angular/core';
@Directive({
  selector: '[appRootImageFallback]'
})
export class RootImageFallbackDirective {
  @Input() appRootImageFallback: string;

  constructor(private eREf: ElementRef) { }

  @HostListener('error')
  loadFallbackOnError() {
    const element: HTMLImageElement = <HTMLImageElement>this.eREf.nativeElement;
    element.src = this.appRootImageFallback || 'https://i.imgur.com/P7bC4ZV.png';
  }
}
