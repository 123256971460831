export class Model
{
  taskName: string;
  clientFirst: string;
  clientLast: string;
  clientEmail: string;
  workType: string;
  duration: string;
  editor: string;
  dueDate:string;
  message: string;
}